import React, {useEffect, useState} from "react";
import {LoadingComponent} from "../../components/loading/LoadingComponent";
import {Box, DialogTitle, FormGroup, IconButton, Typography} from "@mui/material";
import {
    Button,
    Card,
    CardActions,
    Checkbox,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    Tab,
    TextField
} from "@material-ui/core";
import {
    AddCircle,
    AddCircleOutlineOutlined,
    CancelOutlined,
    CheckCircleOutlined,
    RemoveCircleOutlineOutlined,
    Storefront,
    WhatsApp
} from "@material-ui/icons";
import {Autocomplete, TabContext, TabList, TabPanel} from "@material-ui/lab";
import Notification from "../../components/notifications/Notification";
import {useTranslation} from "react-i18next";
import {useHistory, useParams} from "react-router-dom";
import CountryService from "../../geo/countries/CountryService";
import AdministrativeLevel3Service from "../../geo/administrative_level_3/AdministrativeLevel3Service";
import CityService from "../../geo/cities/CityService";
import PostalCodeService from "../../geo/postal_codes/PostalCodeService";
import AccountAddressValues from "../AccountAddressValues";
import AccountService from "../AccountService";
import {makeStyles} from "@material-ui/core/styles";
import DateFnsUtils from "@date-io/date-fns";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import itLocale from "date-fns/locale/it";
import {compareAsc} from "date-fns";
import CompanyService from "../../companies/CompanyService";
import WarehouseService from "../../warehouses/WarehouseService";
import ContractService from "./ContractService";
import theme from "../../theme/theme";
import RateService from "../../rates/RateService";
import MessageTemplatesPage from "./message_templates/MessageTemplatesPage";
import ShipmentService from "../../shipments/ShipmentService";
import ServiceService from "../../shipments/services/ServiceService";

const useStyles = makeStyles({
    root: {
        width: '100%',
        height: "100%",
    },
    field: {
        display: "flex",
        margin: "auto",
        marginBottom: "3%",
        marginTop: "3%",
        width: '30%',
    },
    option: {
        backgroundColor: 'white',
        fontSize: 15,
        '& > span': {
            marginRight: 10,
            fontSize: 18,
        },
    },
    address_container: {
        width: 600,
        display: "block"
    },
    address_field: {
        width: "60%",
        margin: "auto",
        marginTop: "3%"
    },
    column: {
        width: "25%"
    },

    button_container: {
        display: "flex",
        alignItems: 'center',
        justifyContent: 'center',
        marginBlock: "4%",
        paddingBottom: "2%"
    },
    cancel_button: {
        marginInline: "4%",

        width: "25%",
        background: 'rgba(186,186,186,0.84)',

        color: "#000000",
        transition: "all 0.2s",
        // border: "2px solid green",

        "&:hover": {
            backgroundColor: "#d5d5d5",
        },
    },
    create_button: {
        margin: "4%",
        width: "25%",
        backgroundColor: "whitesmoke"
    },
    confirm_button: {
        marginInline: "4%",

        width: "25%",
        backgroundColor: "green",
        color: "white",
        transition: "all 0.2s",
        // border: "2px solid green",

        "&:hover": {
            background: 'rgba(24,147,0,0.84)',
            // color: "black",
            // border:  "2px solid green",

            "& $confirm_icon": {
                // color: "green"
            }
        },
    },
    confirm_icon: {
        color: "white",
        transition: "all 0.3s",
    },
    checkbox: {
        color: theme.palette.primary.main
    },
    checkbox_label: {
        margin: "auto",
        width: "fit-content"
    }
});


function ContractForm(props) {
    const classes = useStyles();
    const history = useHistory();

    const localeMap = {
        it: itLocale,
    };

    const [locale, setLocale] = useState("it");

    const [tabNumber, setTabNumber] = useState('1');

    const [contractCode, setContractCode] = useState('');
    const [contractName, setContractName] = useState('');
    const [validFrom, setValidFrom] = useState(null);
    const [validUntil, setValidUntil] = useState(null);
    const [company, setCompany] = useState(null);
    const [departureWarehouse, setDepartureWarehouse] = useState(null);
    const [addresses, setAddresses] = useState([]);
    const [warehouseStorageNotificationEmail, setWarehouseStorageNotificationEmail] = useState([{email: ""}]);
    const [dedicatedCustomerCareEmail, setDedicatedCustomerCareEmail] = useState('');
    const [operatorEmail, setOperatorEmail] = useState('');
    const [numOfAllowedDeliveryAttempts, setNumOfAllowedDeliveryAttempts] = useState('');
    const [direction, setDirection] = useState("SELL");

    const [services, setServices] = useState([]);
    const [defaultService, setDefaultService] = useState(null);

    const [loading, setLoading] = useState(true);

    const [openNotify, setOpenNotify] = useState(false);
    const [notifySeverity, setNotifySeverity] = useState('');
    const [notificationMessage, setNotificationMessage] = useState(null);

    const [addingAddress, setAddingAddress] = useState(false);

    let types = ["REGISTERED_OFFICE", "OPERATING_CENTER", "WAREHOUSE_RETURN", "COD_CHEQUES_RETURN"];
    let bankAccountTypes = ["COD"];

    const [enabledAbiEventCodes, setEnabledAbiEventCodes] = useState([]);

    const [codModes, setCodModes] = useState([]);
    const [enabledCodModes, setEnabledCodModes] = useState([]);

    const [codEmail, setCodEmail] = useState('');

    const [countries, setCountries] = useState([]);
    const [admin3List, setAdmin3List] = useState([]);
    const [cities, setCities] = useState([]);
    const [postalCodes, setPostalCodes] = useState([]);

    const [refresh, setRefresh] = useState(false);

    const [country, setCountry] = useState(null);
    const [adminL3, setAdminL3] = useState(null);
    const [freeAdministrativeLevel1, setFreeAdministrativeLevel1] = useState('');
    const [freeAdministrativeLevel2, setFreeAdministrativeLevel2] = useState('');
    const [freeAdministrativeLevel3, setFreeAdministrativeLevel3] = useState('');
    const [freeCity, setFreeCity] = useState('');
    const [freePostalCode, setFreePostalCode] = useState('');
    const [city, setCity] = useState(null);
    const [addressCompanyName, setAddressCompanyName] = useState('');
    const [postalCode, setPostalCode] = useState(null);
    const [street, setStreet] = useState('');
    const [buildingNr, setBuildingNr] = useState('');
    const [hamlet, setHamlet] = useState(null);

    /** Notifications */
    const [registeredEmailChecked, setRegisteredEmailChecked] = useState(false);
    const [registeredTextChecked, setRegisteredTextChecked] = useState(false);
    const [takenInChargeEmailChecked, setTakenInChargeEmailChecked] = useState(false);
    const [takenInChargeTextChecked, setTakenInChargeTextChecked] = useState(false);
    const [inDeliveryEmailChecked, setInDeliveryEmailChecked] = useState(false);
    const [inDeliveryTextChecked, setInDeliveryTextChecked] = useState(false);
    const [deliveredEmailChecked, setDeliveredEmailChecked] = useState(false);
    const [deliveredTextChecked, setDeliveredTextChecked] = useState(false);
    const [deliveryKOEmailChecked, setDeliveryKOEmailChecked] = useState(false);
    const [deliveryKOTextChecked, setDeliveryKOTextChecked] = useState(false);
    const [warehouseStorageEmailChecked, setWarehouseStorageEmailChecked] = useState(false);
    const [warehouseStorageTextChecked, setWarehouseStorageTextChecked] = useState(false);
    const [pinCodeEmailChecked, setPinCodeEmailChecked] = useState(false);
    const [pinCodeTextChecked, setPinCodeTextChecked] = useState(false);
    const [returnRegisteredEmailChecked, setReturnRegisteredEmailChecked] = useState(false);
    const [returnRegisteredTextChecked, setReturnRegisteredTextChecked] = useState(false);
    const [returnCollectedEmailChecked, setReturnCollectedEmailChecked] = useState(false);
    const [returnCollectedTextChecked, setReturnCollectedTextChecked] = useState(false);
    const [returnDeliveredEmailChecked, setReturnDeliveredEmailChecked] = useState(false);
    const [returnDeliveredTextChecked, setReturnDeliveredTextChecked] = useState(false);
    const [pudoCheckInTextChecked, setPudoCheckInTextChecked] = useState(false);
    const [pudoCheckInEmailChecked, setPudoCheckInEmailChecked] = useState(false);

    // ratesForContract
    const [rates, setRates] = useState([]);
    const [ratesForContract, setRatesForContract] = useState([]);

    const [bankAccountsForContract, setBankAccountForContracts] = useState([]);

    const [exchangeConfigurations, setExchangeConfigurations] = useState([]);

    const [accounts, setAccounts] = useState([]);
    const [account, setAccount] = useState(null);

    const [companies, setCompanies] = useState([]);
    const [warehouses, setWarehouses] = useState([]);

    const [phone, setPhone] = useState('');
    const [mobile, setMobile] = useState('');
    const [email, setEmail] = useState('');

    const [whatsappStream, setWhatsappStream] = useState(false);
    const [canReturnShipment, setCanReturnShipment] = useState(false);
    const [pudoChangeRequest, setPudoChangeRequest] = useState(false);
    const [returnFeedbackText, setReturnFeedbackText] = useState('');
    const [partialDepartureDisabled, setPartialDepartureDisabled] = useState(false);
    const [partialDeliveryDisabled, setPartialDeliveryDisabled] = useState(false);
    const [blockDepartureWithoutMandatoryBooking, setBlockDepartureWithoutMandatoryBooking] = useState(false);
    const [blockDeliveryWithoutMandatoryBooking, setBlockDeliveryWithoutMandatoryBooking] = useState(false);
    const [taxReleaseWithoutProvision, setTaxReleaseWithoutProvision] = useState(false);

    const [syncShipmentToAbi, setSyncShipmentToAbi] = useState(false);
    const [abiIDCompany, setAbiIDCompany] = useState('');
    const [tradingPartner, setTradingPartner] = useState('');
    const [sendDeliveryOnly, setSendDeliveryOnly] = useState(false);
    const [sendEventWarehouseCode, setSendEventWarehouseCode] = useState(false);
    const [tmsCompany, setTmsCompany] = useState(null);
    const [tmsSystem, setTmsSystem] = useState('');

    const {id} = useParams();

    const {t} = useTranslation();

    function handleTmsCompanyChange(event) {
        const re = /^[0-9\b]+$/;
        // if value is not blank, then test the regex
        if (event.target.value === '' || re.test(event.target.value)) {
            setTmsCompany(event.target.value);
        }
    }

    function addConfiguration() {

        setExchangeConfigurations([...exchangeConfigurations, {
            configType: null,
            host: null,
            port: null,
            username: null,
            password: null,
            passiveMode: null,
            directory: null,
            backupBaseDir: null,
            type: null,
            fileFormat: null
        }])

    }

    function removeConfiguration(index) {

        let temp = exchangeConfigurations;
        temp.splice(index, 1);
        setExchangeConfigurations(temp);
        setRefresh(!refresh);

    }

    function addWarehouseStorageNotificationEmail() {
        setWarehouseStorageNotificationEmail([...warehouseStorageNotificationEmail, {email: ""}])
    }

    function removeWarehouseStorageNotificationEmail(index) {

        let newEmails = warehouseStorageNotificationEmail;
        newEmails.splice(index, 1);
        setWarehouseStorageNotificationEmail(newEmails);
        setRefresh(!refresh);
    }

    function openAddressMask() {
        setAddingAddress(true);
    }

    function addRateForContract() {
        const newRateForContract = {};
        const newRatesForContract = [...ratesForContract, newRateForContract];
        setRatesForContract(newRatesForContract);
    }

    function addBankAccountForContract() {
        const newBankAccountForContract = {};
        newBankAccountForContract.bankAccount = {bankName: "", agencyName: "", iban: ""};
        newBankAccountForContract.isDefault = false;
        newBankAccountForContract.type = "";
        const newBankAccountsForContracts = [...bankAccountsForContract, newBankAccountForContract];
        setBankAccountForContracts(newBankAccountsForContracts);
    }

    function removeRateForContract(index) {
        let newRatesForContract = [...ratesForContract];
        newRatesForContract.splice(index, 1);
        setRatesForContract(newRatesForContract);
    }

    function removeBankAccountForContract(index) {
        let newBankAccountsForContract = [...bankAccountsForContract];
        newBankAccountsForContract.splice(index, 1);
        setBankAccountForContracts(newBankAccountsForContract);
    }

    function cancelAddAddress() {
        setAddingAddress(false);
        clearData();
    }

    async function getEnabledAbiEventCodes(contractCodes) {

        await new ContractService().getEnabledAbiEventCodes()
            .then(result => {

                let codes = [];

                for (let i = 0; i < result.length; i++) {

                    let code = result[i];

                    code.checked = !!contractCodes.includes(result[i].id);

                    codes.push(code);

                }
                setEnabledAbiEventCodes(codes);
            });


    }

    function handleEventCodeCheckChange(event, index){

        let newAbiEventCode = enabledAbiEventCodes;
        newAbiEventCode[index].checked = event.target.checked;
        setEnabledAbiEventCodes(newAbiEventCode);

        setRefresh(!refresh);
    }

    function generateAbiEventCodesCheckboxes(){

        return <FormGroup style={{display: "flex", flexFlow: "column wrap", height: 500, overflow: "auto"}}>
            {enabledAbiEventCodes.map((eventCode, index) => {

                return <FormControlLabel style={{marginLeft: "1%"}}
                    label={<Typography fontSize={10} variant={"button"}>{eventCode.description}</Typography>}
                    control={
                        <Checkbox
                            color={"primary"}
                            checked={eventCode.checked}
                            onChange={event => handleEventCodeCheckChange(event, index)}
                        />
                    }
                    labelPlacement={"right"}
                />

            })}
        </FormGroup>

    }


    function generateNotificationCheckboxes() {

        let fontSize = 10;

        return <div style={{display: "flex"}}>
            <div>
                <FormControlLabel
                    label={<Typography fontSize={fontSize} variant={"button"}>{t("shipment_registered")}</Typography>}
                    control={
                        <Checkbox
                            color={"primary"}
                            checked={registeredEmailChecked && registeredTextChecked}
                            indeterminate={registeredEmailChecked !== registeredTextChecked}
                            onChange={handleRegisteredBothChecked}
                        />
                    }
                    labelPlacement={"top"}
                />
                <Box sx={{display: 'flex', flexDirection: 'column', ml: 0}}>
                    <FormControlLabel
                        label={<Typography fontSize={fontSize} variant={"button"}>{"email"}</Typography>}
                        control={<Checkbox color={"primary"} checked={registeredEmailChecked}
                                           onChange={handleRegisteredEmailChecked}/>}
                        labelPlacement={"top"}
                    />
                    <FormControlLabel
                        label={<Typography fontSize={fontSize} variant={"button"}>{t("text_message")}</Typography>}
                        control={<Checkbox color={"primary"} checked={registeredTextChecked}
                                           onChange={handleRegisteredTextChecked}/>}
                        labelPlacement={"top"}
                    />
                </Box>
            </div>
            <div>
                <FormControlLabel
                    label={<Typography fontSize={fontSize}
                                       variant={"button"}>{t("shipment_taken_in_charge")}</Typography>}
                    control={
                        <Checkbox
                            color={"primary"}
                            checked={takenInChargeEmailChecked && takenInChargeTextChecked}
                            indeterminate={takenInChargeEmailChecked !== takenInChargeTextChecked}
                            onChange={handleTakenInChargeBothChecked}
                        />
                    }
                    labelPlacement={"top"}
                />
                <Box sx={{display: 'flex', flexDirection: 'column', ml: 0}}>
                    <FormControlLabel
                        label={<Typography fontSize={fontSize} variant={"button"}>{"email"}</Typography>}
                        control={<Checkbox color={"primary"} checked={takenInChargeEmailChecked}
                                           onChange={handleTakenInChargeEmailChecked}/>}
                        labelPlacement={"top"}
                    />
                    <FormControlLabel
                        label={<Typography fontSize={fontSize} variant={"button"}>{t("text_message")}</Typography>}
                        control={<Checkbox color={"primary"} checked={takenInChargeTextChecked}
                                           onChange={handleTakenInChargeTextChecked}/>}
                        labelPlacement={"top"}
                    />
                </Box>
            </div>
            <div>
                <FormControlLabel
                    label={<Typography fontSize={fontSize} variant={"button"}>{t("shipment_in_delivery")}</Typography>}
                    control={
                        <Checkbox
                            color={"primary"}
                            checked={inDeliveryEmailChecked && inDeliveryTextChecked}
                            indeterminate={inDeliveryEmailChecked !== inDeliveryTextChecked}
                            onChange={handleInDeliveryBothChecked}
                        />
                    }
                    labelPlacement={"top"}
                />
                <Box sx={{display: 'flex', flexDirection: 'column', ml: 0}}>
                    <FormControlLabel
                        label={<Typography fontSize={fontSize} variant={"button"}>{"email"}</Typography>}
                        control={<Checkbox color={"primary"} checked={inDeliveryEmailChecked}
                                           onChange={handleInDeliveryEmailChecked}/>}
                        labelPlacement={"top"}
                    />
                    <FormControlLabel
                        label={<Typography fontSize={fontSize} variant={"button"}>{t("text_message")}</Typography>}
                        control={<Checkbox color={"primary"} checked={inDeliveryTextChecked}
                                           onChange={handleInDeliveryTextChecked}/>}
                        labelPlacement={"top"}
                    />
                </Box>
            </div>
            <div>
                <FormControlLabel
                    label={<Typography fontSize={fontSize} variant={"button"}>{t("shipment_delivered")}</Typography>}
                    control={
                        <Checkbox
                            color={"primary"}
                            checked={deliveredEmailChecked && deliveredTextChecked}
                            indeterminate={deliveredEmailChecked !== deliveredTextChecked}
                            onChange={handleDeliveredBothChecked}
                        />
                    }
                    labelPlacement={"top"}
                />
                <Box sx={{display: 'flex', flexDirection: 'column', ml: 0}}>
                    <FormControlLabel
                        label={<Typography fontSize={fontSize} variant={"button"}>{"email"}</Typography>}
                        control={<Checkbox color={"primary"} checked={deliveredEmailChecked}
                                           onChange={handleDeliveredEmailChecked}/>}
                        labelPlacement={"top"}
                    />
                    <FormControlLabel
                        label={<Typography fontSize={fontSize} variant={"button"}>{t("text_message")}</Typography>}
                        control={<Checkbox color={"primary"} checked={deliveredTextChecked}
                                           onChange={handleDeliveredTextChecked}/>}
                        labelPlacement={"top"}
                    />
                </Box>
            </div>
            <div>
                <FormControlLabel
                    label={<Typography fontSize={fontSize} variant={"button"}>{t("shipment_delivery_ko")}</Typography>}
                    control={
                        <Checkbox
                            color={"primary"}
                            checked={deliveryKOEmailChecked && deliveryKOTextChecked}
                            indeterminate={deliveryKOEmailChecked !== deliveryKOTextChecked}
                            onChange={handleDeliveryKOBothChecked}
                        />
                    }
                    labelPlacement={"top"}
                />
                <Box sx={{display: 'flex', flexDirection: 'column', ml: 0}}>
                    <FormControlLabel
                        label={<Typography fontSize={fontSize} variant={"button"}>{"email"}</Typography>}
                        control={<Checkbox color={"primary"} checked={deliveryKOEmailChecked}
                                           onChange={handleDeliveryKOEmailChecked}/>}
                        labelPlacement={"top"}
                    />
                    <FormControlLabel
                        label={<Typography fontSize={fontSize} variant={"button"}>{t("text_message")}</Typography>}
                        control={<Checkbox color={"primary"} checked={deliveryKOTextChecked}
                                           onChange={handleDeliveryKOTextChecked}/>}
                        labelPlacement={"top"}
                    />
                </Box>
            </div>
            <div>
                <FormControlLabel
                    label={<Typography fontSize={fontSize} variant={"button"}>{t("shipment_pin_code")}</Typography>}
                    control={
                        <Checkbox
                            color={"primary"}
                            checked={pinCodeEmailChecked && pinCodeTextChecked}
                            indeterminate={pinCodeEmailChecked !== pinCodeTextChecked}
                            onChange={handlePinCodeBothChecked}
                        />
                    }
                    labelPlacement={"top"}
                />
                <Box sx={{display: 'flex', flexDirection: 'column', ml: 0}}>
                    <FormControlLabel
                        label={<Typography fontSize={fontSize} variant={"button"}>{"email"}</Typography>}
                        control={<Checkbox color={"primary"} checked={pinCodeEmailChecked}
                                           onChange={handlePinCodeEmailChecked}/>}
                        labelPlacement={"top"}
                    />
                    <FormControlLabel
                        label={<Typography fontSize={fontSize} variant={"button"}>{t("text_message")}</Typography>}
                        control={<Checkbox color={"primary"} checked={pinCodeTextChecked}
                                           onChange={handlePinCodeTextChecked}/>}
                        labelPlacement={"top"}
                    />
                </Box>
            </div>
            <div>
                <FormControlLabel
                    label={<Typography fontSize={fontSize} variant={"button"}>{t("in_warehouse_storage")}</Typography>}
                    control={
                        <Checkbox
                            color={"primary"}
                            checked={warehouseStorageEmailChecked && warehouseStorageTextChecked}
                            indeterminate={warehouseStorageEmailChecked !== warehouseStorageTextChecked}
                            onChange={handleWarehouseStorageBothChecked}
                        />
                    }
                    labelPlacement={"top"}
                />
                <Box sx={{display: 'flex', flexDirection: 'column', ml: 0}}>
                    <FormControlLabel
                        label={<Typography fontSize={fontSize} variant={"button"}>{"email"}</Typography>}
                        control={<Checkbox color={"primary"} checked={warehouseStorageEmailChecked}
                                           onChange={handleWarehouseStorageEmailChecked}/>}
                        labelPlacement={"top"}
                    />
                    <FormControlLabel
                        label={<Typography fontSize={fontSize} variant={"button"}>{t("text_message")}</Typography>}
                        control={<Checkbox color={"primary"} checked={warehouseStorageTextChecked}
                                           onChange={handleWarehouseStorageTextChecked}/>}
                        labelPlacement={"top"}
                    />
                </Box>
            </div>
            <div>
                <FormControlLabel
                    label={<Typography fontSize={fontSize} variant={"button"}>{t("return_registered")}</Typography>}
                    control={
                        <Checkbox
                            color={"primary"}
                            checked={returnRegisteredEmailChecked && returnRegisteredTextChecked}
                            indeterminate={returnRegisteredEmailChecked !== returnRegisteredTextChecked}
                            onChange={handleReturnRegisteredBothChecked}
                        />
                    }
                    labelPlacement={"top"}
                />
                <Box sx={{display: 'flex', flexDirection: 'column', ml: 0}}>
                    <FormControlLabel
                        label={<Typography fontSize={fontSize} variant={"button"}>{"email"}</Typography>}
                        control={<Checkbox color={"primary"} checked={returnRegisteredEmailChecked}
                                           onChange={handleReturnRegisteredEmailChecked}/>}
                        labelPlacement={"top"}
                    />
                    <FormControlLabel
                        label={<Typography fontSize={fontSize} variant={"button"}>{t("text_message")}</Typography>}
                        control={<Checkbox color={"primary"} checked={returnRegisteredTextChecked}
                                           onChange={handleReturnRegisteredTextChecked}/>}
                        labelPlacement={"top"}
                    />
                </Box>
            </div>
            <div>
                <FormControlLabel
                    label={<Typography fontSize={fontSize} variant={"button"}>{t("return_collected")}</Typography>}
                    control={
                        <Checkbox
                            color={"primary"}
                            checked={returnCollectedEmailChecked && returnCollectedTextChecked}
                            indeterminate={returnCollectedEmailChecked !== returnCollectedTextChecked}
                            onChange={handleReturnCollectedBothChecked}
                        />
                    }
                    labelPlacement={"top"}
                />
                <Box sx={{display: 'flex', flexDirection: 'column', ml: 0}}>
                    <FormControlLabel
                        label={<Typography fontSize={fontSize} variant={"button"}>{"email"}</Typography>}
                        control={<Checkbox color={"primary"} checked={returnCollectedEmailChecked}
                                           onChange={handleReturnCollectedEmailChecked}/>}
                        labelPlacement={"top"}
                    />
                    <FormControlLabel
                        label={<Typography fontSize={fontSize} variant={"button"}>{t("text_message")}</Typography>}
                        control={<Checkbox color={"primary"} checked={returnCollectedTextChecked}
                                           onChange={handleReturnCollectedTextChecked}/>}
                        labelPlacement={"top"}
                    />
                </Box>
            </div>
            <div>
                <FormControlLabel
                    label={<Typography fontSize={fontSize} variant={"button"}>{t("return_delivered")}</Typography>}
                    control={
                        <Checkbox
                            color={"primary"}
                            checked={returnDeliveredEmailChecked && returnDeliveredTextChecked}
                            indeterminate={returnDeliveredEmailChecked !== returnDeliveredTextChecked}
                            onChange={handleReturnDeliveredBothChecked}
                        />
                    }
                    labelPlacement={"top"}
                />
                <Box sx={{display: 'flex', flexDirection: 'column', ml: 0}}>
                    <FormControlLabel
                        label={<Typography fontSize={fontSize} variant={"button"}>{"email"}</Typography>}
                        control={<Checkbox color={"primary"} checked={returnDeliveredEmailChecked}
                                           onChange={handleReturnDeliveredEmailChecked}/>}
                        labelPlacement={"top"}
                    />
                    <FormControlLabel
                        label={<Typography fontSize={fontSize} variant={"button"}>{t("text_message")}</Typography>}
                        control={<Checkbox color={"primary"} checked={returnDeliveredTextChecked}
                                           onChange={handleReturnDeliveredTextChecked}/>}
                        labelPlacement={"top"}
                    />
                </Box>
            </div>
            <div>
                <FormControlLabel
                    label={<Typography fontSize={fontSize} variant={"button"}>{t("pudo_check_in")}</Typography>}
                    control={
                        <Checkbox
                            color={"primary"}
                            checked={pudoCheckInEmailChecked && pudoCheckInTextChecked}
                            indeterminate={pudoCheckInEmailChecked !== pudoCheckInTextChecked}
                            onChange={handlePudoCheckInBothChecked}
                        />
                    }
                    labelPlacement={"top"}
                />
                <Box sx={{display: 'flex', flexDirection: 'column', ml: 0}}>
                    <FormControlLabel
                        label={<Typography fontSize={fontSize} variant={"button"}>{"email"}</Typography>}
                        control={<Checkbox color={"primary"} checked={pudoCheckInEmailChecked}
                                           onChange={handlePudoCheckInEmailChecked}/>}
                        labelPlacement={"top"}
                    />
                    <FormControlLabel
                        label={<Typography fontSize={fontSize} variant={"button"}>{t("text_message")}</Typography>}
                        control={<Checkbox color={"primary"} checked={pudoCheckInTextChecked}
                                           onChange={handlePudoCheckInTextChecked}/>}
                        labelPlacement={"top"}
                    />
                </Box>
            </div>

        </div>
    }

    function generateAddress(country) {
        if (country && (country.id === 106 || country.id === 67 || country.id === 56)) {
            return <div style={{display: "flex", marginBottom: "3%"}}>
                <Autocomplete
                    id="administrativeLevel3"
                    size="small"
                    style={{minWidth: "30%", width: "fit-content", marginInline: "2%"}}
                    options={admin3List}
                    disabled={!country}
                    getOptionLabel={option => option.name}
                    value={adminL3}
                    defaultValue={adminL3}
                    noOptionsText={t("no_options")}
                    onChange={handleAdminL3Change}
                    onOpen={() => fetchAdminL3(country)}
                    ListboxProps={{style: {maxHeight: '15rem'},}}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t("administrative3")}
                            variant="outlined"
                            margin="dense"
                            value={adminL3}
                            defaultValue={adminL3}
                        />
                    )}
                />
                <Autocomplete
                    id="city"
                    size="small"
                    style={{minWidth: "30%", width: "fit-content", marginInline: "2%"}}
                    options={cities}
                    disabled={!adminL3}
                    getOptionLabel={option => option.name}
                    value={city}
                    defaultValue={city}
                    noOptionsText={t("no_options")}
                    onChange={handleCityChange}
                    onOpen={() => fetchCities(adminL3)}
                    ListboxProps={{style: {maxHeight: '15rem'},}}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t("city")}
                            variant="outlined"
                            margin="dense"
                            value={city}
                            defaultValue={city}
                            required
                            error={!city}
                            helperText={!adminL3 ? t("select_admin3_first") : (!city ? t("required") : null)}
                        />
                    )}
                />
                <Autocomplete
                    id="postalCode"
                    size="small"
                    style={{minWidth: "30%", width: "fit-content", marginInline: "1%"}}
                    options={postalCodes}
                    getOptionLabel={option => option.postalCode}
                    value={postalCode}
                    defaultValue={postalCode}
                    noOptionsText={t("no_options")}
                    onChange={handlePostalCodeChange}
                    onOpen={() => fetchPostalCodes(city, adminL3)}
                    ListboxProps={{style: {maxHeight: '15rem'},}}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t("postalCode")}
                            variant="outlined"
                            margin="dense"
                            value={postalCode}
                            defaultValue={postalCode}
                            required
                            error={!postalCode}
                            helperText={!postalCode ? t("required") : null}
                        />
                    )}
                />

            </div>
        } else {
            return <>
                <div style={{display: "flex", marginBottom: "5%"}}>
                    <TextField
                        style={{marginInline: "2%"}}
                        label={t("administrative1")}
                        variant={"outlined"}
                        size={"small"}
                        value={freeAdministrativeLevel1}
                        onChange={handleFreeAdministrativeLevel1Change}
                    />
                    <TextField
                        style={{marginInline: "2%"}}
                        label={t("administrative2")}
                        variant={"outlined"}
                        size={"small"}
                        value={freeAdministrativeLevel2}
                        onChange={handleFreeAdministrativeLevel2Change}
                    />
                </div>
                <div style={{display: "flex", marginBottom: "1%"}}>
                    <TextField
                        style={{marginInline: "2%"}}
                        label={t("administrative3")}
                        variant={"outlined"}
                        size={"small"}
                        value={freeAdministrativeLevel3}
                        onChange={handleFreeAdministrativeLevel3Change}
                    />
                    <TextField
                        style={{marginInline: "2%"}}
                        label={t("city")}
                        variant={"outlined"}
                        size={"small"}
                        value={freeCity}
                        required
                        error={!freeCity}
                        helperText={!freeCity ? t("required") : null}
                        onChange={handleFreeCityChange}
                    />
                    <TextField
                        style={{marginInline: "2%"}}
                        label={t("postalCode")}
                        variant={"outlined"}
                        size={"small"}
                        value={freePostalCode}
                        onChange={handleFreePostalCodeChange}
                        required
                        error={!freePostalCode}
                        helperText={!freePostalCode ? t("required") : null}
                    />
                </div>
            </>
        }
    }

    function handleClose() {
        history.push('/contracts');
    }

    async function fetchDataAndHandleEdit() {
        setLoading(true);

        let accountService = new AccountService();
        const acc = await accountService.getAccounts()
        setAccounts(acc);

        let companyService = new CompanyService();
        const comps = await companyService.getCompanies();
        setCompanies(comps);

        let warehouseService = new WarehouseService();
        const warehouseResponse = await warehouseService.getWarehousesByFilters(0, 1000, null)
        const wares = warehouseResponse.warehouses;
        setWarehouses(wares);

        const services = await new ServiceService().getServicesByFilters();
        setServices(services);

        if (!id) {
            await getEnabledAbiEventCodes([])
            setLoading(false);
            return;
        }

        // edit case

        let contractService = new ContractService();
        const contract = await contractService.getContractById(id)
            .catch(error => {
                error.response.json().then((response) => {
                    setNotifySeverity('error');
                    setNotificationMessage(response.message);
                    setOpenNotify(true);
                    setLoading(false);
                });
            })

        for (let i = 0; i < contract.notifications.length; ++i) {
            const notification = contract.notifications[i];
            switch (notification.type) {
                case "SHIPMENT_REGISTERED":
                    setRegisteredEmailChecked(notification.email);
                    setRegisteredTextChecked(notification.textMessage);
                    break;
                case "SHIPMENT_TAKEN_IN_CHARGE":
                    setTakenInChargeEmailChecked(notification.email);
                    setTakenInChargeTextChecked(notification.textMessage);
                    break;
                case "IN_DELIVERY":
                    setInDeliveryEmailChecked(notification.email);
                    setInDeliveryTextChecked(notification.textMessage);
                    break;
                case "DELIVERED":
                    setDeliveredEmailChecked(notification.email);
                    setDeliveredTextChecked(notification.textMessage);
                    break;
                case "DELIVERY_KO":
                    setDeliveryKOEmailChecked(notification.email);
                    setDeliveryKOTextChecked(notification.textMessage);
                    break;
                case "WAREHOUSE_STORAGE":
                    setWarehouseStorageEmailChecked(notification.email);
                    setWarehouseStorageTextChecked(notification.textMessage);
                    break;
                case "PIN_CODE":
                    setPinCodeEmailChecked(notification.email);
                    setPinCodeTextChecked(notification.textMessage);
                    break;
                case "RETURN_REGISTERED":
                    setReturnRegisteredEmailChecked(notification.email);
                    setReturnRegisteredTextChecked(notification.textMessage);
                    break;
                case "RETURN_COLLECTED":
                    setReturnCollectedEmailChecked(notification.email);
                    setReturnCollectedTextChecked(notification.textMessage);
                    break;
                case "RETURN_DELIVERED":
                    setReturnDeliveredEmailChecked(notification.email);
                    setReturnDeliveredTextChecked(notification.textMessage);
                    break;
                case "PUDO_CHECK_IN":
                    setPudoCheckInEmailChecked(notification.email);
                    setPudoCheckInTextChecked(notification.textMessage);
                    break;
                default:
                    break;
            }
        }

        setAddresses(contract.addresses);

        setContractCode(contract.code);
        setContractName(contract.name);
        setValidFrom(contract.validFrom);
        setValidUntil(contract.validUntil);
        setWhatsappStream(contract.whatsappStream);
        setCanReturnShipment(contract.canReturnShipment);
        setPudoChangeRequest(contract.pudoChangeRequest);
        if (null != contract.warehouseStorageNotificationEmail) {
            let emails = contract.warehouseStorageNotificationEmail.split(";")
            let warehouseStorageNotificationEmail = [];
            for (let i = 0; i < emails.length; i++) {
                let email = {};
                email.email = emails[i];
                warehouseStorageNotificationEmail.push(email);
            }
            setWarehouseStorageNotificationEmail(warehouseStorageNotificationEmail);
        }
        setDedicatedCustomerCareEmail(contract.dedicatedCustomerCareEmail);
        setOperatorEmail(contract.operatorEmail);
        setCodEmail(contract.codEmail);
        setNumOfAllowedDeliveryAttempts(contract.numOfAllowedDeliveryAttempts);
        setDirection(contract.direction);
        setBankAccountForContracts(contract.bankAccounts);
        setPartialDepartureDisabled(contract.partialDepartureDisabled);
        setPartialDeliveryDisabled(contract.partialDeliveryDisabled);
        setBlockDepartureWithoutMandatoryBooking(contract.blockDepartureWithoutMandatoryBooking);
        setBlockDeliveryWithoutMandatoryBooking(contract.blockDeliveryWithoutMandatoryBooking);
        setTaxReleaseWithoutProvision(contract.taxReleaseWithoutProvision);

        if (null != contract.abiConfiguration) {

            setTradingPartner(contract.abiConfiguration.tradingPartner);
            setAbiIDCompany(contract.abiConfiguration.abiIDCompany);
            setSyncShipmentToAbi(contract.abiConfiguration.syncShipmentToAbi);
            // setSendDeliveryOnly(contract.abiConfiguration.sendDeliveryOnly);
            setSendEventWarehouseCode(contract.abiConfiguration.sendEventWarehouseCode)
            setTmsSystem(contract.abiConfiguration.tmsSystem);
            setTmsCompany(contract.abiConfiguration.tmsCompany);

            await getEnabledAbiEventCodes(contract.abiConfiguration.eventCodes?.map(value => value.id))

        }

        let exchangeConfigs = [];

        if(null != contract.exchangeConfigurations && contract.exchangeConfigurations.length !== 0) {

            for (let i = 0; i < contract.exchangeConfigurations.length; i++) {

                let config = {};
                config.configType = contract.exchangeConfigurations[i].configType;
                config.type = contract.exchangeConfigurations[i].type;
                config.fileFormat = contract.exchangeConfigurations[i].fileFormat;
                config.host = contract.exchangeConfigurations[i].host;
                config.port = contract.exchangeConfigurations[i].port;
                config.username = contract.exchangeConfigurations[i].username;
                config.password = contract.exchangeConfigurations[i].password;
                config.passiveMode = contract.exchangeConfigurations[i].passiveMode?.toString();
                config.directory = contract.exchangeConfigurations[i].directory;
                config.backupBaseDir = contract.exchangeConfigurations[i].backupBaseDir;

                exchangeConfigs.push(config);
            }

        }

        setExchangeConfigurations(exchangeConfigs);


        let newCodModes = await new ShipmentService().getCodModes();

        for (let i = 0; i < newCodModes.length; i++) {
            for (let j = 0; j < contract.enabledCodModes.length; j++) {
                if (newCodModes[i].id === contract.enabledCodModes[j].id) {
                    newCodModes[i].checked = true;
                }
            }
        }

        setCodModes(newCodModes);


        if (contract.returnFeedbackText !== null) {
            setReturnFeedbackText(contract.returnFeedbackText)
        }

        if (contract.rates) {
            const ratesForContract = [];
            for (let i = 0; i < contract.rates.length; ++i) {
                const rateForContract = {
                    warehouseCode: contract.rates[i].warehouseCode,
                    rateId: contract.rates[i].rate.id,
                    validFrom: contract.rates[i].validFrom,
                    validUntil: contract.rates[i].validUntil,
                };
                ratesForContract.push(rateForContract);
            }
            setRatesForContract(ratesForContract);
        }

        if (contract.defaultServiceId) {
            for (let i = 0; i < services.length; ++i) {
                if (contract.defaultServiceId === services[i].id) {
                    setDefaultService(services[i]);
                    break;
                }
            }
        }

        if (contract.accountId) {
            for (let i = 0; i < acc.length; ++i) {
                if (contract.accountId === acc[i].id) {
                    setAccount(acc[i]);
                    break;
                }
            }
        }

        if (contract.companyId) {
            for (let i = 0; i < comps.length; ++i) {
                if (contract.companyId === comps[i].id) {
                    setCompany(comps[i]);
                    break;
                }
            }
        }

        if (contract.departureWarehouseId) {
            for (let i = 0; i < wares.length; ++i) {
                if (contract.departureWarehouseId === wares[i].id) {
                    setDepartureWarehouse(wares[i]);
                    break;
                }
            }
        }

        setLoading(false);
    }

    function fetchCountries() {
        let countryService = new CountryService();
        countryService.getCountriesByFilters(null, null, null, null).then(data => {
            setCountries(data.countries);
        })
    }

    function fetchRates() {
        let rateService = new RateService();
        rateService.getByFilters(null, null, null).then(data => {
            setRates(data.rates);
        });
    }

    function fetchAdminL3(country) {

        let filters = {};
        filters.countryId = country.id;

        let administrativeLevel3Service = new AdministrativeLevel3Service();
        administrativeLevel3Service.getAllAdminLevel3WithFilters(null, null, filters, null)
            .then(data => {
                setAdmin3List(data.admins3)
            })
    }

    function fetchCities(administrativeLevel3) {
        let filters = {};
        if (administrativeLevel3) {
            filters.level3Id = administrativeLevel3.id;
        }

        let cityService = new CityService();
        cityService.getCities(null, null, filters, null)
            .then(data => {
                setCities(data.cities);
            })
            .catch(error => {

            })
    }

    function fetchPostalCodes(city, adminL3) {
        let filters = {};
        if (!city && adminL3) {
            filters.administrativeLevel3Id = adminL3.id;
        }
        if (city) {
            filters.cityId = city.id;
        }

        let postalCodeService = new PostalCodeService();
        postalCodeService.getAllPostalCodes(null, null, filters, null)
            .then(data => {
                setPostalCodes(data.postalCodes);
            })
            .catch(error => {

            })
    }

    async function fetchCodModes() {

        await new ShipmentService().getCodModes()
            .then(result => {
                setCodModes(result)
            })
            .catch(error => {

            })
    }

    function handleCodModeChange(event, index) {
        let newCodModes = [...codModes];
        newCodModes[index].checked = event.target.checked;
        setCodModes(newCodModes);
    }

    function handleHamletChange(event, hamlet) {
        setHamlet(hamlet);
    }


    function handleCountryChange(event, country) {

        setCountry(country);
        setAdminL3(null);
        setCity(null);
        setPostalCode(null);
        setHamlet(null);

    }

    function handleAdminL3Change(event, adminL3) {
        setAdminL3(adminL3);
        setCity(null);
        setPostalCode(null);
        setHamlet(null);
    }

    function handleCityChange(event, city) {
        setCity(city);
        setPostalCode(null);
        setHamlet(null);
    }

    function handlePostalCodeChange(event, postalCode) {
        setHamlet(null);
        setPostalCode(postalCode);
    }

    function handleAddressValue(index, event, key) {
        let newAddresses = [...addresses];
        newAddresses[index][key] = event.target.value;
        newAddresses[index].default = false;
        setAddresses(newAddresses);
    }

    function handleCheckboxValue(index, event, key) {
        let newAddresses = [...addresses];
        newAddresses[index][key] = event.target.checked;
        for (let i = 0; i < newAddresses.length; i++) {
            if (i !== index && newAddresses[i].type === newAddresses[index].type) {
                newAddresses[i][key] = false;
            }
        }
        setAddresses(newAddresses);
    }

    function closeNotification() {
        setOpenNotify(false);
    }

    function addAddress() {
        if (!country ||
            (!adminL3 && !freeAdministrativeLevel3) ||
            (!postalCode && !freePostalCode) ||
            (!city && !freeCity) ||
            !addressCompanyName ||
            !street
        ) {
            setOpenNotify(true);
            setNotifySeverity('error');
            setNotificationMessage("Inserire tutti i campi obbligatori");
        } else {
            let address = new AccountAddressValues();

            let addressData = {}


            addressData.country = country.iso3166Alpha2;


            if (country.id === 106 || country.id === 67 || country.id === 56) {
                addressData.administrativeLevel3 = adminL3.code;
                addressData.city = city.name;
                addressData.postalCode = postalCode.postalCode;
            } else {
                addressData.administrativeLevel1 = freeAdministrativeLevel1;
                addressData.administrativeLevel2 = freeAdministrativeLevel2;
                addressData.administrativeLevel3 = freeAdministrativeLevel3;
                addressData.city = freeCity;
                addressData.postalCode = freePostalCode;
            }

            addressData.street = street;
            addressData.buildingNr = buildingNr;
            addressData.companyName = addressCompanyName;
            addressData.email = email;
            addressData.phone = phone;
            addressData.mobile = mobile;

            address.address = addressData;

            // addresses.push(address);
            const newAddresses = [...addresses, address];
            setAddresses(newAddresses);

            clearData();
            setAddingAddress(false);
            // setRefresh(!refresh);
        }
    }

    function removeAddress(index) {
        let newAddresses = addresses;
        newAddresses.splice(index, 1);
        setAddresses(newAddresses);
        // setRefresh(!refresh);
    }

    function clearData() {
        setCountry(null);
        setAdminL3(null);
        setCity(null);
        setPostalCode(null);
        setFreeAdministrativeLevel1('');
        setFreeAdministrativeLevel2('');
        setFreeAdministrativeLevel3('');
        setFreeCity('');
        setFreePostalCode('');
        setStreet('');
        setBuildingNr('');
        setAddressCompanyName('');
        setEmail('');
        setMobile('');
        setPhone('');
    }

    async function handleSubmit() {
        if ('' === contractName ||
            '' === contractCode ||
            null === validFrom ||
            null === account ||
            null === company
        ) {
            setOpenNotify(true);
            setNotifySeverity('error');
            setNotificationMessage(t("void_required_fields"));
        } else {

            let notifications = [];

            if (registeredTextChecked || registeredEmailChecked) {
                let notification = {};
                notification.type = "SHIPMENT_REGISTERED";
                notification.email = registeredEmailChecked;
                notification.textMessage = registeredTextChecked;

                notifications.push(notification);
            }
            if (takenInChargeTextChecked || takenInChargeEmailChecked) {
                let notification = {};
                notification.type = "SHIPMENT_TAKEN_IN_CHARGE";
                notification.email = takenInChargeEmailChecked;
                notification.textMessage = takenInChargeTextChecked;

                notifications.push(notification);
            }
            if (inDeliveryTextChecked || inDeliveryEmailChecked) {
                let notification = {};
                notification.type = "IN_DELIVERY";
                notification.email = inDeliveryEmailChecked;
                notification.textMessage = inDeliveryTextChecked;

                notifications.push(notification);
            }
            if (deliveredTextChecked || deliveredEmailChecked) {
                let notification = {};
                notification.type = "DELIVERED";
                notification.email = deliveredEmailChecked;
                notification.textMessage = deliveredTextChecked;

                notifications.push(notification);
            }
            if (deliveryKOTextChecked || deliveryKOEmailChecked) {
                let notification = {};
                notification.type = "DELIVERY_KO";
                notification.email = deliveryKOEmailChecked;
                notification.textMessage = deliveryKOTextChecked;

                notifications.push(notification);
            }
            if (warehouseStorageTextChecked || warehouseStorageEmailChecked) {
                let notification = {};
                notification.type = "WAREHOUSE_STORAGE";
                notification.email = warehouseStorageEmailChecked;
                notification.textMessage = warehouseStorageTextChecked;

                notifications.push(notification);
            }

            if (pinCodeTextChecked || pinCodeEmailChecked) {
                let notification = {};
                notification.type = "PIN_CODE";
                notification.email = pinCodeEmailChecked;
                notification.textMessage = pinCodeTextChecked;

                notifications.push(notification);
            }
            if (returnRegisteredEmailChecked || returnRegisteredTextChecked) {
                let notification = {};
                notification.type = "RETURN_REGISTERED";
                notification.email = returnRegisteredEmailChecked;
                notification.textMessage = returnRegisteredTextChecked;

                notifications.push(notification);
            }
            if (returnCollectedEmailChecked || returnCollectedTextChecked) {
                let notification = {};
                notification.type = "RETURN_COLLECTED";
                notification.email = returnCollectedEmailChecked;
                notification.textMessage = returnCollectedTextChecked;

                notifications.push(notification);
            }
            if (returnDeliveredEmailChecked || returnDeliveredTextChecked) {
                let notification = {};
                notification.type = "RETURN_DELIVERED";
                notification.email = returnDeliveredEmailChecked;
                notification.textMessage = returnDeliveredTextChecked;

                notifications.push(notification);
            }
            if (pudoCheckInEmailChecked || pudoCheckInTextChecked) {
                let notification = {};
                notification.type = "PUDO_CHECK_IN";
                notification.email = pudoCheckInEmailChecked;
                notification.textMessage = pudoCheckInTextChecked;

                notifications.push(notification);
            }


            let contract = {};
            if (id) {
                contract.id = id;
            }

            contract.notifications = notifications;
            contract.addresses = addresses;

            contract.name = contractName;
            contract.code = contractCode;
            contract.accountId = account.id;
            contract.companyId = company.id;
            contract.departureWarehouseId = null != departureWarehouse ? departureWarehouse.id : null;
            contract.validFrom = validFrom;
            contract.validUntil = validUntil;
            contract.whatsappStream = whatsappStream;
            contract.pudoChangeRequest = pudoChangeRequest;
            contract.canReturnShipment = canReturnShipment;
            contract.returnFeedbackText = returnFeedbackText;
            contract.rates = ratesForContract;

            let emailString = "";
            for (let i = 0; i < warehouseStorageNotificationEmail.length; i++) {
                if (warehouseStorageNotificationEmail[i].email !== "" && warehouseStorageNotificationEmail[i].email !== null) {
                    emailString = i > 0 ? emailString + ";" : emailString;
                    emailString += warehouseStorageNotificationEmail[i].email
                }
            }

            contract.warehouseStorageNotificationEmail = emailString;
            contract.dedicatedCustomerCareEmail = dedicatedCustomerCareEmail;
            contract.operatorEmail = operatorEmail;
            contract.codEmail = codEmail;
            contract.numOfAllowedDeliveryAttempts = numOfAllowedDeliveryAttempts;
            contract.direction = direction;
            contract.defaultServiceId = defaultService?.id;
            contract.partialDepartureDisabled = partialDepartureDisabled;
            contract.partialDeliveryDisabled = partialDeliveryDisabled;
            contract.blockDeliveryWithoutMandatoryBooking = blockDeliveryWithoutMandatoryBooking;
            contract.blockDepartureWithoutMandatoryBooking = blockDepartureWithoutMandatoryBooking;
            contract.taxReleaseWithoutProvision = taxReleaseWithoutProvision;

            let abiConfiguration = {};

            abiConfiguration.syncShipmentToAbi = syncShipmentToAbi;
            // abiConfiguration.sendDeliveryOnly = sendDeliveryOnly;
            abiConfiguration.sendEventWarehouseCode = sendEventWarehouseCode;
            abiConfiguration.tradingPartner = tradingPartner;
            abiConfiguration.abiIDCompany = abiIDCompany;
            abiConfiguration.tmsCompany = tmsCompany;
            abiConfiguration.tmsSystem = tmsSystem;
            abiConfiguration.eventCodeIds = enabledAbiEventCodes.filter(eventCode => eventCode.checked).map(value => value.id);

            contract.abiConfiguration = abiConfiguration;

            let configurationsInputs = [];

            for (let i = 0; i < exchangeConfigurations.length; i++) {

                let configInput = {};
                configInput.configurationType = exchangeConfigurations[i].configType;
                configInput.type = exchangeConfigurations[i].type;
                configInput.fileFormat = exchangeConfigurations[i].fileFormat;
                configInput.host = exchangeConfigurations[i].host;
                configInput.port = exchangeConfigurations[i].port;
                configInput.username = exchangeConfigurations[i].username;
                configInput.password = exchangeConfigurations[i].password;
                configInput.passiveMode = exchangeConfigurations[i].passiveMode;
                configInput.directory = exchangeConfigurations[i].directory;
                configInput.backupBaseDir = exchangeConfigurations[i].backupBaseDir;

                configurationsInputs.push(configInput);
            }

            contract.contractExchangeConfigurations = configurationsInputs;

            contract.bankAccounts = bankAccountsForContract;

            let newCodModes = [];

            for (let i = 0; i < codModes.length; i++) {
                if (codModes[i].checked) {
                    newCodModes.push(codModes[i].id);
                }
            }

            contract.enabledCodModes = newCodModes;

            let contractService = new ContractService();
            await contractService.saveContract(contract)
                .then(result => {
                    setNotifySeverity('success');
                    setNotificationMessage(t("successful"))
                    setOpenNotify(true);
                    setTimeout(() => {
                        history.goBack();
                    }, 2000)
                })
                .catch(error => {
                    error.response.json().then((response) => {
                        setNotifySeverity('error');
                        setNotificationMessage(response.message);
                        setOpenNotify(true);
                    })
                });
        }
    }

    function handleFreeAdministrativeLevel1Change(event) {
        setFreeAdministrativeLevel1(event.target.value);
    }

    function handleFreeAdministrativeLevel2Change(event) {
        setFreeAdministrativeLevel2(event.target.value);
    }

    function handleFreeAdministrativeLevel3Change(event) {
        setFreeAdministrativeLevel3(event.target.value);
    }

    function handleFreeCityChange(event) {
        setFreeCity(event.target.value);
    }

    function handleFreePostalCodeChange(event) {
        setFreePostalCode(event.target.value);
    }

    function handleStreetChange(event) {
        setStreet(event.target.value);
    }

    function handleBuildingNrChange(event) {
        setBuildingNr(event.target.value)
    }

    function handleAddressCompanyNameChange(event) {
        setAddressCompanyName(event.target.value)
    }

    function handleEmailChange(event) {
        setEmail(event.target.value)
    }

    function handlePhoneChange(event) {
        setPhone(event.target.value)
    }

    function handleMobileChange(event) {
        setMobile(event.target.value)
    }


    function handleContractCodeChange(event) {
        setContractCode(event.target.value);
    }

    function handleContractNameChange(event) {
        setContractName(event.target.value);
    }

    function handleAccountChange(event, account) {
        // setAccount(event.target.value);
        setAccount(account);
    }

    function handleValidFromChange(event) {
        if (compareAsc(event, validUntil) === 1) {
            setValidFrom(event);
            setValidUntil(null);
        } else {
            setValidFrom(event)
        }
    }

    function handleValidUntilChange(event) {
        if (compareAsc(validFrom, event) === 1) {
            setValidFrom(null);
            setValidUntil(event);
        } else {
            setValidUntil(event);
        }
    }

    function handleCompanyChange(event, company) {
        // setCompany(event.target.value);
        setCompany(company);
    }

    function handleWarehouseStorageNotificationEmailChange(event, index) {
        let email = [...warehouseStorageNotificationEmail];
        email[index].email = event.target.value;
        setWarehouseStorageNotificationEmail(email);
    }

    function handleDedicatedCustomerCareEmail(event) {
        setDedicatedCustomerCareEmail(event.target.value);
    }

    function handleOperatorEmailChange(event) {
        setOperatorEmail(event.target.value);
    }

    function handleDefaultServiceChange(event, service) {
        setDefaultService(service);
    }

    function handleCodEmailChange(event) {
        setCodEmail(event.target.value);
    }

    function handleNumOfAllowedDeliveryAttempts(event) {
        setNumOfAllowedDeliveryAttempts(event.target.value);
    }

    function handleDepartureWarehouseChange(event, warehouse) {
        // setDepartureWarehouse(event.target.value);
        setDepartureWarehouse(warehouse);
    }

    function handleBankAccountForContractBankNameChange(event, index) {
        const newBankAccountsForContract = [...bankAccountsForContract];
        newBankAccountsForContract[index].bankAccount.bankName = event.target.value;
        setBankAccountForContracts(newBankAccountsForContract);
    }

    function handleBankAccountForContractAgencyNameChange(event, index) {
        const newBankAccountsForContract = [...bankAccountsForContract];
        newBankAccountsForContract[index].bankAccount.agencyName = event.target.value;
        setBankAccountForContracts(newBankAccountsForContract);
    }

    function handleBankAccountForContractIbanChange(event, index) {
        const newBankAccountsForContract = [...bankAccountsForContract];
        newBankAccountsForContract[index].bankAccount.iban = event.target.value;
        setBankAccountForContracts(newBankAccountsForContract);
    }

    function handleBankAccountForContractTypeChange(event, index) {
        const newBankAccountsForContract = [...bankAccountsForContract];
        newBankAccountsForContract[index].type = event.target.value;
        setBankAccountForContracts(newBankAccountsForContract);
    }

    function handleRateForContractRateChange(rate, index) {
        const newRatesForContract = [...ratesForContract];
        newRatesForContract[index].rateId = rate.id;
        setRatesForContract(newRatesForContract);
    }

    function handleRateForContractWarehouseChange(warehouse, index) {
        const newRatesForContract = [...ratesForContract];
        newRatesForContract[index].warehouseCode = null != warehouse ? warehouse.code : null;
        setRatesForContract(newRatesForContract);
    }

    function handleRateForContractValidFrom(dateFrom, index) {
        const newRatesForContract = [...ratesForContract];
        newRatesForContract[index].validFrom = dateFrom;
        setRatesForContract(newRatesForContract);
    }

    function handleRateForContractValidUntil(dateUntil, index) {
        const newRatesForContract = [...ratesForContract];
        newRatesForContract[index].validUntil = dateUntil;
        setRatesForContract(newRatesForContract);
    }

    function handleRegisteredEmailChecked(event) {
        setRegisteredEmailChecked(event.target.checked);
    }

    function handleRegisteredTextChecked(event) {
        setRegisteredTextChecked(event.target.checked);
    }

    function handleTakenInChargeEmailChecked(event) {
        setTakenInChargeEmailChecked(event.target.checked);
    }

    function handleTakenInChargeTextChecked(event) {
        setTakenInChargeTextChecked(event.target.checked);
    }

    function handleRegisteredBothChecked(event) {
        setRegisteredEmailChecked(event.target.checked);
        setRegisteredTextChecked(event.target.checked);
    }

    function handleTakenInChargeBothChecked(event) {
        setTakenInChargeEmailChecked(event.target.checked);
        setTakenInChargeTextChecked(event.target.checked);
    }

    function handleInDeliveryEmailChecked(event) {
        setInDeliveryEmailChecked(event.target.checked);
    }

    function handleInDeliveryTextChecked(event) {
        setInDeliveryTextChecked(event.target.checked);
    }

    function handleInDeliveryBothChecked(event) {
        setInDeliveryEmailChecked(event.target.checked);
        setInDeliveryTextChecked(event.target.checked);
    }

    function handleDeliveredEmailChecked(event) {
        setDeliveredEmailChecked(event.target.checked);
    }

    function handleDeliveredTextChecked(event) {
        setDeliveredTextChecked(event.target.checked);
    }

    function handleDeliveredBothChecked(event) {
        setDeliveredEmailChecked(event.target.checked);
        setDeliveredTextChecked(event.target.checked);
    }

    function handleDeliveryKOEmailChecked(event) {
        setDeliveryKOEmailChecked(event.target.checked);
    }

    function handleDeliveryKOTextChecked(event) {
        setDeliveryKOTextChecked(event.target.checked);
    }

    function handleDeliveryKOBothChecked(event) {
        setDeliveryKOEmailChecked(event.target.checked);
        setDeliveryKOTextChecked(event.target.checked);
    }

    function handleWarehouseStorageEmailChecked(event) {
        setWarehouseStorageEmailChecked(event.target.checked);
    }

    function handleWarehouseStorageTextChecked(event) {
        setWarehouseStorageTextChecked(event.target.checked);
    }

    function handleWarehouseStorageBothChecked(event) {
        setWarehouseStorageEmailChecked(event.target.checked);
        setWarehouseStorageTextChecked(event.target.checked);
    }

    function handlePinCodeEmailChecked(event) {
        setPinCodeEmailChecked(event.target.checked);
    }

    function handlePinCodeTextChecked(event) {
        setPinCodeTextChecked(event.target.checked);
    }

    function handlePinCodeBothChecked(event) {
        setPinCodeEmailChecked(event.target.checked);
        setPinCodeTextChecked(event.target.checked);
    }

    function handleReturnRegisteredEmailChecked(event) {
        setReturnRegisteredEmailChecked(event.target.checked);
    }

    function handleReturnRegisteredTextChecked(event) {
        setReturnRegisteredTextChecked(event.target.checked);
    }

    function handleReturnRegisteredBothChecked(event) {
        setReturnRegisteredEmailChecked(event.target.checked);
        setReturnRegisteredTextChecked(event.target.checked);
    }

    function handleReturnCollectedEmailChecked(event) {
        setReturnCollectedEmailChecked(event.target.checked);
    }

    function handleReturnCollectedTextChecked(event) {
        setReturnCollectedTextChecked(event.target.checked);
    }

    function handleReturnCollectedBothChecked(event) {
        setReturnCollectedEmailChecked(event.target.checked);
        setReturnCollectedTextChecked(event.target.checked);
    }


    function handleReturnDeliveredEmailChecked(event) {
        setReturnDeliveredEmailChecked(event.target.checked);
    }

    function handleReturnDeliveredTextChecked(event) {
        setReturnDeliveredTextChecked(event.target.checked);
    }

    function handleReturnDeliveredBothChecked(event) {
        setReturnDeliveredEmailChecked(event.target.checked);
        setReturnDeliveredTextChecked(event.target.checked);
    }

    function handlePudoCheckInEmailChecked(event) {
        setPudoCheckInEmailChecked(event.target.checked);
    }

    function handlePudoCheckInTextChecked(event) {
        setPudoCheckInTextChecked(event.target.checked);
    }

    function handlePudoCheckInBothChecked(event) {
        setPudoCheckInEmailChecked(event.target.checked);
        setPudoCheckInTextChecked(event.target.checked);
    }

    function handleTabChange(event, newValue) {
        setTabNumber(newValue);
    }

    useEffect(() => {
        fetchCodModes();
        fetchCountries();
        fetchDataAndHandleEdit();
        fetchRates();
        // setLoading(false);
    }, []);

    useEffect(() => {

    }, [refresh])

    return <div>

        {(loading) ?

            <LoadingComponent/>

            :

            <>
                <TabContext value={tabNumber}>
                    <Box sx={{
                        width: "fit-content",
                        borderBottom: 0,
                        borderColor: theme.palette.primary.main,
                        margin: "auto"
                    }}>
                        <TabList onChange={handleTabChange} aria-label="tabs-about-me">
                            <Tab label={t("contract_data")} value="1"/>
                            <Tab label={t("message_templates")} value="2"/>
                            {/*<Tab label={t("annotations")} value="3"/>*/}
                            {/*<Tab label={t("administration")} value="4"/>*/}
                        </TabList>
                    </Box>
                    <TabPanel style={{width: "100%"}} value="1">
                        {/*<DialogTitle><Typography variant={"button"}>{t("contract_data")}</Typography></DialogTitle>*/}

                        <Card style={{width: "95%", margin: "auto"}}>
                            <TextField
                                required
                                error={!contractCode}
                                helperText={!contractCode ? t("required") : null}
                                label={t("contract_code")}
                                variant="outlined"
                                type="text"
                                margin="dense"
                                onChange={handleContractCodeChange}
                                value={contractCode}
                                className={classes.field}
                            />

                            <TextField
                                required
                                error={!contractName}
                                helperText={!contractName ? t("required") : null}
                                label={t("contract_name")}
                                variant="outlined"
                                type="text"
                                margin="dense"
                                onChange={handleContractNameChange}
                                value={contractName}
                                className={classes.field}
                            />
                            <div>
                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap[locale]}>
                                    <Grid container justify="space-around">
                                        <DatePicker
                                            autoOk
                                            disableToolbar
                                            required
                                            error={!validFrom}
                                            helperText={!validFrom ? t("required") : null}
                                            format="dd/MM/yyyy"
                                            okLabel={null}
                                            clearLabel={t("reset")}
                                            cancelLabel={t("cancel")}
                                            margin="dense"
                                            inputVariant="outlined"
                                            label={t("valid_from")}
                                            clearable
                                            value={validFrom}
                                            onChange={handleValidFromChange}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                        />
                                        <DatePicker
                                            autoOk
                                            disableToolbar
                                            format="dd/MM/yyyy"
                                            okLabel={null}
                                            clearLabel={t("reset")}
                                            cancelLabel={t("cancel")}
                                            margin="dense"
                                            label={t("valid_until")}
                                            inputVariant="outlined"
                                            clearable
                                            value={validUntil}
                                            onChange={handleValidUntilChange}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                        />
                                    </Grid>
                                </MuiPickersUtilsProvider>

                            </div>
                            <div>
                                <Autocomplete
                                    id="accountId"
                                    size="small"
                                    style={{minWidth: "35%", width: "fit-content", marginBottom: "2%", margin: "auto"}}
                                    options={accounts}
                                    getOptionLabel={account => account.accountCorporateCode + " - " + account.companyName}
                                    value={account}
                                    defaultValue={account}
                                    required
                                    error={!account}
                                    helperText={!account ? t("required") : null}
                                    onChange={handleAccountChange}
                                    ListboxProps={{style: {maxHeight: '15rem'},}}
                                    noOptionsText={t("no_options")}
                                    renderInput={(params) => <TextField
                                        {...params}
                                        label={t("account")}
                                        variant="outlined"
                                        margin="dense"
                                        value={account}
                                        defaultValue={account}
                                        required
                                        error={!account}
                                        helperText={!account ? t("required") : null}
                                    />}
                                />
                            </div>
                            <div>
                                <Autocomplete
                                    id="companyId"
                                    size="small"
                                    style={{minWidth: "35%", width: "fit-content", marginBottom: "2%", margin: "auto"}}
                                    options={companies}
                                    getOptionLabel={company => company.shortName + " - " + company.companyName}
                                    value={company}
                                    defaultValue={company}
                                    required
                                    error={!company}
                                    helperText={!company ? t("required") : null}
                                    onChange={handleCompanyChange}
                                    ListboxProps={{style: {maxHeight: '15rem'},}}
                                    noOptionsText={t("no_options")}
                                    renderInput={(params) => <TextField
                                        {...params}
                                        label={t("company")}
                                        variant="outlined"
                                        margin="dense"
                                        value={company}
                                        defaultValue={company}
                                        required
                                        error={!company}
                                        helperText={!company ? t("required") : null}
                                    />}
                                />
                            </div>
                            <div style={{
                                marginTop: "5%",
                                marginBottom: "2%",
                                height: "20%",
                                backgroundColor: theme.palette.primary.main,
                                display: "flex"
                            }}>
                                <DialogTitle><Typography variant={"button"}
                                                         color={"white"}>{t("contract_options")}</Typography></DialogTitle>
                            </div>
                            <div style={{display: "flex", flexDirection: "column"}}>
                                <FormControlLabel
                                    className={classes.checkbox_label}
                                    label={<Typography style={{margin: "auto"}}
                                                       fontSize={13}
                                                       variant={"button"}>{t("partial_departure_disabled")}</Typography>}
                                    control={
                                        <Checkbox
                                            color={"primary"}
                                            checked={partialDepartureDisabled}
                                            onChange={event => setPartialDepartureDisabled(event.target.checked)}
                                        />
                                    }
                                    labelPlacement={"right"}
                                />
                                <FormControlLabel
                                    className={classes.checkbox_label}
                                    label={<Typography style={{margin: "auto"}}
                                                       fontSize={13}
                                                       variant={"button"}>{t("partial_delivery_disabled")}</Typography>}
                                    control={
                                        <Checkbox
                                            color={"primary"}
                                            checked={partialDeliveryDisabled}
                                            onChange={event => setPartialDeliveryDisabled(event.target.checked)}
                                        />
                                    }
                                    labelPlacement={"right"}
                                />
                                <FormControlLabel
                                    className={classes.checkbox_label}
                                    label={<Typography style={{margin: "auto"}}
                                                       fontSize={13}
                                                       variant={"button"}>{t("block_departure_without_mandatory_booking")}</Typography>}
                                    control={
                                        <Checkbox
                                            color={"primary"}
                                            checked={blockDepartureWithoutMandatoryBooking}
                                            onChange={event => setBlockDepartureWithoutMandatoryBooking(event.target.checked)}
                                        />
                                    }
                                    labelPlacement={"right"}
                                />
                                <FormControlLabel
                                    className={classes.checkbox_label}
                                    label={<Typography style={{margin: "auto"}}
                                                       fontSize={13}
                                                       variant={"button"}>{t("block_delivery_without_mandatory_booking")}</Typography>}
                                    control={
                                        <Checkbox
                                            color={"primary"}
                                            checked={blockDeliveryWithoutMandatoryBooking}
                                            onChange={event => setBlockDeliveryWithoutMandatoryBooking(event.target.checked)}
                                        />
                                    }
                                    labelPlacement={"right"}
                                />
                                <FormControlLabel
                                    className={classes.checkbox_label}
                                    label={<Typography style={{margin: "auto"}}
                                                       fontSize={13}
                                                       variant={"button"}>{t("tax_release_without_provision")}</Typography>}
                                    control={
                                        <Checkbox
                                            color={"primary"}
                                            checked={taxReleaseWithoutProvision}
                                            onChange={event => setTaxReleaseWithoutProvision(event.target.checked)}
                                        />
                                    }
                                    labelPlacement={"right"}
                                />
                                <FormControlLabel
                                    className={classes.checkbox_label}
                                    label={<div style={{display: "flex", alignItems: "center"}}>
                                        <Typography style={{margin: "auto"}}
                                                    fontSize={13}
                                                    variant={"button"}>{t("pudo_change_request_flag")}</Typography>

                                        &nbsp;

                                        <Storefront/></div>}
                                    control={
                                        <Checkbox
                                            color={"primary"}
                                            checked={pudoChangeRequest}
                                            onChange={event => setPudoChangeRequest(event.target.checked)}
                                        />
                                    }
                                    labelPlacement={"right"}
                                />
                                <FormControlLabel
                                    className={classes.checkbox_label}
                                    label={<div style={{display: "flex", alignItems: "center"}}>
                                        <Typography style={{margin: "auto"}}
                                                    fontSize={13}
                                                    variant={"button"}>{t("whatsapp_stream")}</Typography>

                                        &nbsp;

                                        <WhatsApp style={{color: "#25D366"}}/></div>}
                                    control={
                                        <Checkbox
                                            color={"primary"}
                                            checked={whatsappStream}
                                            onChange={event => setWhatsappStream(event.target.checked)}
                                        />
                                    }
                                    labelPlacement={"right"}
                                />
                            </div>
                            <div style={{display: "flex"}}>
                                <FormControlLabel
                                    style={{marginBlock: "5%", margin: "auto"}}
                                    label={<Typography style={{margin: "auto"}}
                                                       fontSize={16}
                                                       variant={"button"}>{t("can_be_returned")}</Typography>}
                                    control={
                                        <Checkbox
                                            color={"primary"}
                                            checked={canReturnShipment}
                                            onChange={event => {
                                                setCanReturnShipment(event.target.checked);
                                                if (false === event.target.checked) {
                                                    setReturnFeedbackText('')
                                                }
                                            }}
                                        />
                                    }
                                    labelPlacement={"top"}
                                />
                                {/*<div style={{width: "50%"}}>*/}
                                {/*    <IconButton onClick={()=>setEditReturnFeedbackText(!editReturnFeedbackText)}><Edit/></IconButton>*/}
                                <TextField
                                    disabled={!canReturnShipment}
                                    style={{margin: "auto", width: "70%"}}
                                    variant={"outlined"}
                                    value={returnFeedbackText}
                                    onChange={event => setReturnFeedbackText(event.target.value)}
                                    multiline
                                    maxRows={4}
                                    minRows={4}
                                    label={t("return_feedback_text")}
                                />
                            </div>
                            <div style={{marginTop: "5%"}}>
                                {warehouseStorageNotificationEmail.map((email, index) => {

                                    return <div style={{width: "50%", display: "flex", margin: "auto"}}>
                                        <div style={{width: "90%", margin: "auto"}}>
                                            <TextField
                                                label={t("warehouse_storage_notification_email") + " " + (index + 1)}
                                                variant="outlined"
                                                type="text"
                                                margin="dense"
                                                onChange={(event) => handleWarehouseStorageNotificationEmailChange(event, index)}
                                                value={email.email}
                                                style={{width: "100%"}}
                                            />
                                        </div>
                                        {warehouseStorageNotificationEmail.length > 1 ?
                                            <IconButton style={{margin: "auto"}}
                                                        onClick={() => removeWarehouseStorageNotificationEmail(index)}>
                                                <CancelOutlined color={"error"}/>
                                            </IconButton>
                                            :
                                            null
                                        }
                                    </div>
                                })}
                                <Button style={{marginBlock: "2%"}} variant={"outlined"}
                                        onClick={addWarehouseStorageNotificationEmail}>{t("add_email")}</Button>
                            </div>
                            <div>
                                <TextField
                                    label={t("numOfAllowedDeliveryAttempts")}
                                    variant="outlined"
                                    type="text"
                                    margin="dense"
                                    onChange={handleNumOfAllowedDeliveryAttempts}
                                    value={numOfAllowedDeliveryAttempts}
                                    className={classes.field}
                                />
                            </div>
                            <div>
                                <TextField
                                    label={t("dedicated_customer_care_email")}
                                    variant="outlined"
                                    type="text"
                                    margin="dense"
                                    onChange={handleDedicatedCustomerCareEmail}
                                    value={dedicatedCustomerCareEmail}
                                    className={classes.field}
                                />
                            </div>
                            <div>
                                <TextField
                                    label={t("operator_email")}
                                    variant="outlined"
                                    type="text"
                                    margin="dense"
                                    onChange={handleOperatorEmailChange}
                                    value={operatorEmail}
                                    className={classes.field}
                                />
                            </div>
                            <div>
                                <Autocomplete
                                    id="defaultService"
                                    size="small"
                                    style={{minWidth: "35%", width: "fit-content", marginBottom: "2%", margin: "auto"}}
                                    options={services}
                                    getOptionLabel={option => option.code + " - " + option.name}
                                    value={defaultService}
                                    defaultValue={defaultService}
                                    onChange={handleDefaultServiceChange}
                                    ListboxProps={{style: {maxHeight: '15rem'},}}
                                    noOptionsText={t("no_options")}
                                    renderInput={(params) => <TextField
                                        {...params}
                                        label={t("default_service")}
                                        variant="outlined"
                                        margin="dense"
                                        value={defaultService}
                                        defaultValue={defaultService}
                                    />}
                                />
                            </div>
                            <div>
                                <RadioGroup
                                    row
                                    value={direction}
                                    onChange={(event, value) => setDirection(value)}
                                    className={classes.field}
                                >
                                    <FormControlLabel value={"SELL"} control={<Radio color={"primary"}/>}
                                                      label={t("sales_contract")}/>
                                    <FormControlLabel value={"BUY"} control={<Radio color={"primary"}/>}
                                                      label={t("purchase_contract")}/>
                                </RadioGroup>
                            </div>
                            {/*</div>*/}

                            <div>
                                <div style={{
                                    marginTop: "5%",
                                    marginBottom: "2%",
                                    height: "20%",
                                    backgroundColor: theme.palette.primary.main,
                                    display: "flex"
                                }}>
                                    <DialogTitle><Typography variant={"button"}
                                                             color={"white"}>{t("shipment_notifications")}</Typography></DialogTitle>
                                </div>
                                {generateNotificationCheckboxes()}
                            </div>

                            <div style={{
                                marginTop: "5%",
                                marginBottom: "2%",
                                height: "20%",
                                backgroundColor: theme.palette.primary.main,
                                display: "flex"
                            }}>
                                <DialogTitle><Typography variant={"button"}
                                                         color={"white"}>{t("default_departure_warehouse_for_data_entry")}</Typography></DialogTitle>
                            </div>
                            <div>
                                <Autocomplete
                                    id="departureWarehouseId"
                                    size="small"
                                    style={{minWidth: "35%", width: "fit-content", marginBottom: "2%", margin: "auto"}}
                                    options={warehouses}
                                    getOptionLabel={warehouse => warehouse.code + " - " + warehouse.name}
                                    value={departureWarehouse}
                                    defaultValue={departureWarehouse}
                                    onChange={handleDepartureWarehouseChange}
                                    ListboxProps={{style: {maxHeight: '15rem'},}}
                                    noOptionsText={t("no_options")}
                                    renderInput={(params) => <TextField
                                        {...params}
                                        label={t("departure_warehouse")}
                                        variant="outlined"
                                        margin="dense"
                                        value={departureWarehouse}
                                        defaultValue={departureWarehouse}
                                    />}
                                />
                            </div>

                            <div style={{
                                marginTop: "5%",
                                marginBottom: "2%",
                                height: "20%",
                                backgroundColor: theme.palette.primary.main,
                                display: "flex"
                            }}>
                                <DialogTitle><Typography variant={"button"}
                                                         color={"white"}>{t("listini")}</Typography></DialogTitle>
                            </div>
                            <div>
                                <div>
                                    <Button className={classes.create_button}
                                            endIcon={<AddCircleOutlineOutlined/>}
                                            onClick={addRateForContract}
                                            variant={"contained"}>
                                        {t("add")}
                                    </Button>
                                </div>
                                {ratesForContract.map((rateForContract, index) => {
                                    let rate = null;
                                    for (let i = 0; i < rates.length; ++i) {
                                        if (rateForContract.rateId === rates[i].id) {
                                            rate = rates[i];
                                        }
                                    }

                                    let warehouse = null;
                                    for (let i = 0; i < warehouses.length; ++i) {
                                        if (rateForContract.warehouseCode === warehouses[i].code) {
                                            warehouse = warehouses[i];
                                        }
                                    }

                                    return <div>
                                        <div>
                                            <Button className={classes.create_button}
                                                    endIcon={<RemoveCircleOutlineOutlined/>}
                                                    onClick={() => {
                                                        removeRateForContract(index)
                                                    }}
                                                    variant={"contained"}>
                                                {t("remove")}
                                            </Button>
                                        </div>
                                        <Autocomplete
                                            id="rateId"
                                            size="small"
                                            style={{
                                                minWidth: "35%",
                                                width: "fit-content",
                                                marginBottom: "2%",
                                                margin: "auto"
                                            }}
                                            options={rates}
                                            getOptionLabel={rate => rate.code}
                                            value={rate}
                                            defaultValue={rate}
                                            required
                                            error={!rate}
                                            helperText={!rate ? t("required") : null}
                                            onChange={(event, value) => {
                                                handleRateForContractRateChange(value, index)
                                            }}
                                            ListboxProps={{style: {maxHeight: '15rem'},}}
                                            noOptionsText={t("no_options")}
                                            renderInput={(params) => <TextField
                                                {...params}
                                                label={t("rate")}
                                                variant="outlined"
                                                margin="dense"
                                                value={rate}
                                                defaultValue={rate}
                                                required
                                                error={!rate}
                                                helperText={!rate ? t("required") : null}
                                            />}
                                        />

                                        <Autocomplete
                                            id="warehouseCode"
                                            size="small"
                                            style={{
                                                minWidth: "35%",
                                                width: "fit-content",
                                                marginBottom: "2%",
                                                margin: "auto"
                                            }}
                                            options={warehouses}
                                            getOptionLabel={warehouse => warehouse.name}
                                            value={warehouse}
                                            defaultValue={warehouse}
                                            onChange={(event, value) => {
                                                handleRateForContractWarehouseChange(value, index)
                                            }}
                                            ListboxProps={{style: {maxHeight: '15rem'},}}
                                            noOptionsText={t("no_options")}
                                            renderInput={(params) => <TextField
                                                {...params}
                                                label={t("departure_warehouse")}
                                                variant="outlined"
                                                margin="dense"
                                                value={warehouse}
                                                defaultValue={warehouse}
                                                placeholder={t("any")}
                                            />}
                                        />

                                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap[locale]}>
                                            <Grid container justify="space-around">
                                                <DatePicker
                                                    autoOk
                                                    disableToolbar
                                                    format="dd/MM/yyyy"
                                                    okLabel={null}
                                                    clearLabel={t("reset")}
                                                    cancelLabel={t("cancel")}
                                                    margin="dense"
                                                    inputVariant="outlined"
                                                    label={t("valid_from")}
                                                    clearable
                                                    value={rateForContract.validFrom}
                                                    onChange={(value) => {
                                                        handleRateForContractValidFrom(value, index)
                                                    }}
                                                    KeyboardButtonProps={{
                                                        'aria-label': 'change date',
                                                    }}
                                                />
                                                <DatePicker
                                                    autoOk
                                                    disableToolbar
                                                    format="dd/MM/yyyy"
                                                    okLabel={null}
                                                    clearLabel={t("reset")}
                                                    cancelLabel={t("cancel")}
                                                    margin="dense"
                                                    label={t("valid_until")}
                                                    inputVariant="outlined"
                                                    clearable
                                                    value={rateForContract.validUntil}
                                                    onChange={(value) => {
                                                        handleRateForContractValidUntil(value, index)
                                                    }}
                                                    KeyboardButtonProps={{
                                                        'aria-label': 'change date',
                                                    }}
                                                />
                                            </Grid>
                                        </MuiPickersUtilsProvider>
                                    </div>
                                })}
                            </div>
                            <div style={{
                                marginTop: "5%",
                                marginBottom: "2%",
                                height: "20%",
                                backgroundColor: theme.palette.primary.main,
                                display: "flex"
                            }}>
                                <DialogTitle><Typography variant={"button"}
                                                         color={"white"}>{t("bank_accounts")}</Typography></DialogTitle>
                            </div>
                            <div>
                                <div>
                                    <Button className={classes.create_button}
                                            endIcon={<AddCircleOutlineOutlined/>}
                                            onClick={addBankAccountForContract}
                                            variant={"contained"}>
                                        {t("add")}
                                    </Button>
                                </div>
                                {bankAccountsForContract.map((bankAccountForContract, index) => {

                                    return <div
                                        style={{width: "50%", margin: "auto", border: "1px solid", marginBottom: "2%"}}>
                                        <div>
                                            <IconButton style={{margin: "auto"}}
                                                        onClick={() => {
                                                            removeBankAccountForContract(index)
                                                        }}>
                                                <CancelOutlined/>
                                            </IconButton>
                                        </div>

                                        <TextField
                                            label={t("bank_name")}
                                            variant="outlined"
                                            type="text"
                                            margin="dense"
                                            required
                                            onChange={(event) => {
                                                handleBankAccountForContractBankNameChange(event, index)
                                            }}
                                            error={!bankAccountForContract.bankAccount?.bankName}
                                            value={bankAccountForContract.bankAccount?.bankName}
                                            className={classes.address_field}
                                        />
                                        <TextField
                                            label={t("agency_name")}
                                            variant="outlined"
                                            type="text"
                                            margin="dense"
                                            required
                                            error={!bankAccountForContract.bankAccount?.agencyName}
                                            onChange={(event) => {
                                                handleBankAccountForContractAgencyNameChange(event, index)
                                            }}
                                            value={bankAccountForContract.bankAccount?.agencyName}
                                            className={classes.address_field}
                                        />
                                        <TextField
                                            label={t("iban")}
                                            variant="outlined"
                                            type="text"
                                            margin="dense"
                                            required
                                            onChange={(event) => {
                                                handleBankAccountForContractIbanChange(event, index)
                                            }}
                                            error={!bankAccountForContract.bankAccount?.iban}
                                            value={bankAccountForContract.bankAccount?.iban}
                                            className={classes.address_field}
                                        />
                                        <FormControl
                                            required
                                            variant={"outlined"}
                                            margin={"dense"}
                                            style={{margin: "auto", marginBlock: "5%", width: "45%"}}
                                        >
                                            <InputLabel>{t("bank_account_type")}</InputLabel>
                                            <Select
                                                value={bankAccountForContract.type}
                                                required
                                                error={!bankAccountForContract.type}
                                                label={t("bank_account_type") + "*"}
                                                onChange={(event) => handleBankAccountForContractTypeChange(event, index)}
                                            >
                                                {bankAccountTypes.map((type, index) => {
                                                    return (
                                                        <MenuItem key={index} value={type}>
                                                            {t(type)}
                                                        </MenuItem>
                                                    )
                                                })}
                                            </Select>
                                        </FormControl>

                                    </div>
                                })}
                            </div>
                            <div style={{
                                marginTop: "5%",
                                marginBottom: "2%",
                                height: "20%",
                                backgroundColor: theme.palette.primary.main,
                                display: "flex"
                            }}>
                                <DialogTitle><Typography variant={"button"}
                                                         color={"white"}>{t("cod_modes")}</Typography></DialogTitle>
                            </div>
                            <div>
                                <TextField
                                    label={t("cod_email")}
                                    variant="outlined"
                                    type="text"
                                    margin="dense"
                                    onChange={handleCodEmailChange}
                                    value={codEmail}
                                    className={classes.field}
                                />
                            </div>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: "center",
                                marginBlock: "5%",
                                ml: 1
                            }}>
                                {codModes.map((codMode, index) => {

                                    return <Box sx={{display: 'flex', flexDirection: 'column', ml: 0}}>
                                        <FormControlLabel
                                            label={<Typography fontSize={12}
                                                               variant={"button"}>{codMode.name}</Typography>}
                                            control={<Checkbox color={"primary"} checked={codMode.checked}
                                                               onChange={event => handleCodModeChange(event, index)}/>}
                                            labelPlacement={"bottom"}
                                        />
                                    </Box>

                                    // return <Checkbox value={codMode} aria-label="left aligned">
                                    //     <Card style={{width: 120, height: 175, textAlign: "left", padding: "3%"}}>
                                    //         <Typography variant={"subtitle2"}>{codMode.id}</Typography>
                                    //         <Typography variant={"subtitle2"}>{codMode.name}</Typography>
                                    //     </Card>
                                    // </Checkbox>
                                })}
                            </Box>
                            <div style={{
                                marginTop: "5%",
                                marginBottom: "2%",
                                height: "20%",
                                backgroundColor: theme.palette.primary.main,
                                display: "flex"
                            }}>
                                <DialogTitle align={"center"}><Typography variant={"button"}
                                                         color={"white"}>{t("abi_configuration")}</Typography></DialogTitle>
                            </div>
                            <div style={{display: "flex", flexDirection: "column", width: "100%", margin: "auto"}}>
                                <FormControlLabel
                                    className={classes.checkbox_label}
                                    label={<Typography style={{margin: "auto"}}
                                                       fontSize={13}
                                                       variant={"button"}>{t("sync_shipment_to_abi")}</Typography>}
                                    control={
                                        <Checkbox
                                            color={"primary"}
                                            checked={syncShipmentToAbi}
                                            onChange={event => setSyncShipmentToAbi(event.target.checked)}
                                        />
                                    }
                                    labelPlacement={"right"}
                                />
                                <FormControlLabel
                                    className={classes.checkbox_label}
                                    label={<Typography style={{margin: "auto"}}
                                                       fontSize={13}
                                                       variant={"button"}>{t("send_event_warehouse_code")}</Typography>}
                                    control={
                                        <Checkbox
                                            color={"primary"}
                                            checked={sendEventWarehouseCode}
                                            onChange={event => setSendEventWarehouseCode(event.target.checked)}
                                        />
                                    }
                                    labelPlacement={"right"}
                                />
                                <TextField
                                    style={{marginInline: "auto", width: "30%", marginBlock: "1%"}}
                                    label={t("abi_ID_company")}
                                    variant={"outlined"}
                                    size={"small"}
                                    value={abiIDCompany}
                                    onChange={(event) => setAbiIDCompany(event.target.value)}
                                />
                                <TextField
                                    style={{marginInline: "auto", width: "30%", marginBlock: "1%"}}
                                    label={t("trading_partner")}
                                    variant={"outlined"}
                                    size={"small"}
                                    value={tradingPartner}
                                    onChange={(event) => setTradingPartner(event.target.value)}
                                />
                                <TextField
                                    style={{marginInline: "auto", width: "30%", marginBlock: "1%"}}
                                    label={t("tms_company")}
                                    variant={"outlined"}
                                    size={"small"}
                                    value={tmsCompany}
                                    onChange={handleTmsCompanyChange}
                                />
                                <TextField
                                    style={{marginInline: "auto", width: "30%", marginBlock: "1%"}}
                                    label={t("tms_system")}
                                    variant={"outlined"}
                                    size={"small"}
                                    value={tmsSystem}
                                    onChange={(event) => setTmsSystem(event.target.value)}
                                />
                                {generateAbiEventCodesCheckboxes()}
                            </div>
                            <div style={{
                                marginTop: "5%",
                                marginBottom: "2%",
                                height: "20%",
                                backgroundColor: theme.palette.primary.main,
                                display: "flex"
                            }}>
                                <DialogTitle><Typography variant={"button"}
                                                         color={"white"}>{t("exchange_configurations")}</Typography></DialogTitle>
                            </div>
                            <div>
                                <Button className={classes.create_button}
                                        endIcon={<AddCircleOutlineOutlined/>}
                                        onClick={addConfiguration}
                                        variant={"contained"}>
                                    {t("add_configuration")}
                                </Button>
                            </div>
                            <div style={{display: "flex", flexDirection: "column"}}>
                                {exchangeConfigurations.map((item, index) => {
                                    return <Card style={{width: "90%", margin: "auto", marginBlock: "2%"}}>
                                        <IconButton type={"button"} size={"small"} style={{float: "right"}}
                                                    onClick={() => removeConfiguration(index)}>
                                            <CancelOutlined/>
                                        </IconButton>
                                        <Autocomplete
                                            id="config_type"
                                            size="small"
                                            style={{
                                                minWidth: "20%", width: "fit-content", margin: "auto"
                                            }}
                                            options={["SFTP", "FTP"]}
                                            getOptionLabel={option => option}
                                            value={item.configType}
                                            defaultValue={item.configType}
                                            onChange={(event, value) => {
                                                const newConfigs = [...exchangeConfigurations];
                                                newConfigs[index].configType = value;
                                                setExchangeConfigurations(newConfigs);
                                            }}
                                            ListboxProps={{style: {maxHeight: '15rem'},}}
                                            noOptionsText={t("no_options")}
                                            renderInput={(params) => <TextField
                                                {...params}
                                                label={t("connection_type")}
                                                variant="outlined"
                                                margin="dense"
                                                value={item.configType}
                                                defaultValue={item.configType}
                                            />}
                                        />
                                        <Autocomplete
                                            id="event_type"
                                            size="small"
                                            style={{
                                                minWidth: "20%", width: "fit-content", margin: "auto"
                                            }}
                                            options={['EVENTS', 'MISSING_PARCELS', 'WAREHOUSE_STORAGES', 'SHIPMENTS', 'PODS', 'ROUTING_RULES']}
                                            getOptionLabel={option => option}
                                            value={item.type}
                                            defaultValue={item.type}
                                            onChange={(event, value) => {
                                                const newConfigs = [...exchangeConfigurations];
                                                newConfigs[index].type = value;
                                                setExchangeConfigurations(newConfigs);
                                            }}
                                            ListboxProps={{style: {maxHeight: '15rem'},}}
                                            noOptionsText={t("no_options")}
                                            renderInput={(params) => <TextField
                                                {...params}
                                                label={t("event_type")}
                                                variant="outlined"
                                                margin="dense"
                                                value={item.type}
                                                defaultValue={item.type}
                                            />}
                                        />
                                        <Autocomplete
                                            id="file_format"
                                            size="small"
                                            style={{
                                                minWidth: "20%", width: "fit-content", margin: "auto"
                                            }}
                                            options={['ESPRINET', 'FNVAB', 'STANDARD', 'REV37']}
                                            getOptionLabel={option => option}
                                            value={item.fileFormat}
                                            defaultValue={item.fileFormat}
                                            onChange={(event, value) => {
                                                const newConfigs = [...exchangeConfigurations];
                                                newConfigs[index].fileFormat = value;
                                                setExchangeConfigurations(newConfigs);
                                            }}
                                            ListboxProps={{style: {maxHeight: '15rem'},}}
                                            noOptionsText={t("no_options")}
                                            renderInput={(params) => <TextField
                                                {...params}
                                                label={t("file_format")}
                                                variant="outlined"
                                                margin="dense"
                                                value={item.fileFormat}
                                                defaultValue={item.fileFormat}
                                            />}
                                        />
                                        <TextField
                                            style={{marginInline: "auto", width: "15%", marginBlock: "1%"}}
                                            label={t("host")}
                                            variant={"outlined"}
                                            size={"small"}
                                            value={item.host}
                                            onChange={(event) => {
                                                const newConfigs = [...exchangeConfigurations];
                                                newConfigs[index].host = event.target.value;
                                                setExchangeConfigurations(newConfigs);
                                            }}
                                        />
                                        <TextField
                                            style={{marginInline: "1%", width: "5%", marginBlock: "1%"}}
                                            label={t("port")}
                                            variant={"outlined"}
                                            size={"small"}
                                            value={item.port}
                                            onChange={(event) => {
                                                const newConfigs = [...exchangeConfigurations];
                                                newConfigs[index].port = event.target.value;
                                                setExchangeConfigurations(newConfigs);
                                            }}
                                        />
                                        <TextField
                                            style={{marginInline: "1%", width: "15%", marginBlock: "1%"}}
                                            label={t("username")}
                                            variant={"outlined"}
                                            size={"small"}
                                            value={item.username}
                                            onChange={(event) => {
                                                const newConfigs = [...exchangeConfigurations];
                                                newConfigs[index].username = event.target.value;
                                                setExchangeConfigurations(newConfigs);
                                            }}
                                        />
                                        <TextField
                                            style={{marginInline: "1%", width: "15%", marginBlock: "1%"}}
                                            label={t("password")}
                                            variant={"outlined"}
                                            size={"small"}
                                            value={item.password}
                                            onChange={(event) => {
                                                const newConfigs = [...exchangeConfigurations];
                                                newConfigs[index].password = event.target.value;
                                                setExchangeConfigurations(newConfigs);
                                            }}
                                        />
                                        <TextField
                                            style={{marginInline: "1%", width: "15%", marginBlock: "1%"}}
                                            label={t("directory")}
                                            variant={"outlined"}
                                            size={"small"}
                                            value={item.directory}
                                            onChange={(event) => {
                                                const newConfigs = [...exchangeConfigurations];
                                                newConfigs[index].directory = event.target.value;
                                                setExchangeConfigurations(newConfigs);
                                            }}
                                        />
                                        <TextField
                                            style={{marginInline: "1%", width: "15%", marginBlock: "1%"}}
                                            label={t("backup_directory")}
                                            variant={"outlined"}
                                            size={"small"}
                                            value={item.backupBaseDir}
                                            onChange={(event) => {
                                                const newConfigs = [...exchangeConfigurations];
                                                newConfigs[index].backupBaseDir = event.target.value;
                                                setExchangeConfigurations(newConfigs);
                                            }}
                                        />
                                        {item.configType === "FTP"?
                                            <Autocomplete
                                                id="config_type"
                                                size="small"
                                                style={{minWidth: "20%", width: "fit-content", margin: "auto"}}
                                                options={['true', 'false']}
                                                getOptionLabel={option => option}
                                                value={item.passiveMode}
                                                defaultValue={item.passiveMode}
                                                onChange={(event, value) => {
                                                    const newConfigs = [...exchangeConfigurations];
                                                    newConfigs[index].passiveMode = value;
                                                    setExchangeConfigurations(newConfigs);
                                                }}
                                                ListboxProps={{style: {maxHeight: '15rem'},}}
                                                noOptionsText={t("no_options")}
                                                renderInput={(params) => <TextField
                                                    {...params}
                                                    label={t("passive_mode")}
                                                    variant="outlined"
                                                    margin="dense"
                                                    value={item.passiveMode}
                                                    defaultValue={item.passiveMode}
                                                />}
                                            />
                                            :
                                            null
                                        }
                                    </Card>
                                })}
                            </div>
                        </Card>
                        <Grid item xs={12} style={{marginTop: "5%"}}>
                            <Grid container justifyContent="center" spacing={2}>
                                {addresses.map((address, index) => {
                                    return <Grid item style={{width: "30%"}}>
                                        <Card>
                                            <FormControl
                                                required
                                                variant={"outlined"}
                                                margin={"dense"}
                                                style={{marginBlock: "5%", width: "70%"}}
                                            >
                                                <InputLabel>{t("address_type")}</InputLabel>
                                                <Select
                                                    value={address.type}
                                                    required
                                                    error={!address.type}
                                                    label={t("address_type") + "*"}
                                                    onChange={(event) => handleAddressValue(index, event, 'type')}
                                                >
                                                    {types.map((type, index) => {
                                                        return (
                                                            <MenuItem key={index} value={type}>
                                                                {t(type)}
                                                            </MenuItem>
                                                        )
                                                    })}
                                                </Select>
                                            </FormControl>
                                            <IconButton type={"button"} size={"small"} style={{float: "right"}}
                                                        onClick={() => removeAddress(index)}>
                                                <CancelOutlined/>
                                            </IconButton>
                                            <div>
                                                <FormControlLabel
                                                    label={<Typography fontSize={12}
                                                                       variant={"button"}>{t("default_address")}</Typography>}
                                                    control={
                                                        <Checkbox
                                                            checked={address.default}
                                                            onChange={(event) => handleCheckboxValue(index, event, 'default')}
                                                        />
                                                    }
                                                />
                                            </div>


                                            <Typography align={"left"} marginLeft={"5%"}>
                                                {address.address.companyName}
                                            </Typography>
                                            <Typography align={"left"} marginLeft={"5%"}>
                                                {address.address.street}
                                                {address.address.buildingNr ? ", " + address.address.buildingNr : ""}
                                            </Typography>
                                            <Typography align={"left"} marginLeft={"5%"}>
                                                {address.address.postalCode}
                                                {", " + address.address.city}
                                                {" (" + address.address.administrativeLevel3 + ")"}
                                                {" - " + address.address.country}
                                                {address.address.administrativeLevel1 ? " " + address.address.administrativeLevel1 : ""}
                                                {address.address.administrativeLevel2 ? " " + address.address.administrativeLevel2 : ""}
                                            </Typography>
                                            <Typography align={"left"} marginLeft={"5%"}>
                                                {address.address.email ? "Email: " + address.address.email : ""}
                                            </Typography>
                                            <Typography align={"left"} marginLeft={"5%"}>
                                                {address.address.phone ? "Tel: " + address.address.phone : ""}
                                            </Typography>
                                            <Typography align={"left"} marginLeft={"5%"} marginBottom={"2%"}>
                                                {address.address.mobile ? "Cell: " + address.address.mobile : ""}
                                            </Typography>
                                        </Card>
                                    </Grid>
                                })}
                            </Grid>
                            {!addingAddress ?
                                <div>
                                    <Button className={classes.create_button}
                                            endIcon={<AddCircleOutlineOutlined/>}
                                            onClick={openAddressMask}
                                            variant={"contained"}>
                                        {t("add_address")}
                                    </Button>
                                </div>
                                :

                                <Card style={{width: "60%", margin: "auto", marginTop: "5%"}}>
                                    <Autocomplete
                                        id="country"
                                        size="small"
                                        style={{
                                            minWidth: "30%",
                                            width: "fit-content",
                                            marginBlock: "4%",
                                            marginLeft: "2%"
                                        }}
                                        options={countries}
                                        getOptionLabel={option => option.iso3166Alpha2 + " - " + option.name}
                                        value={country}
                                        defaultValue={country}
                                        onChange={handleCountryChange}
                                        onSelect={fetchCountries}
                                        ListboxProps={{style: {maxHeight: '15rem'},}}
                                        noOptionsText={t("no_options")}
                                        renderInput={(params) => <TextField
                                            {...params}
                                            label={t("country")}
                                            variant="outlined"
                                            margin="dense"
                                            value={country}
                                            defaultValue={country}
                                            required
                                            error={!country}
                                            helperText={!country ? t("required") : null}
                                        />}
                                    />

                                    {generateAddress(country)}

                                    <div style={{display: "flex", marginBlock: "5%"}}>
                                        {/*<div style={{display: "flex", marginBlock: "2%"}}>*/}
                                        <TextField
                                            required
                                            error={!addressCompanyName}
                                            helperText={!addressCompanyName ? t("required") : null}
                                            style={{marginInline: "2%"}}
                                            label={t("address_company_name")}
                                            variant={"outlined"}
                                            size={"small"}
                                            value={addressCompanyName}
                                            onChange={handleAddressCompanyNameChange}
                                        />
                                        {/*</div>*/}
                                        <TextField
                                            style={{marginInline: "2%"}}
                                            label={t("street")}
                                            variant={"outlined"}
                                            size={"small"}
                                            value={street}
                                            required
                                            error={!street}
                                            helperText={!street ? t("required") : null}
                                            onChange={handleStreetChange}
                                        />
                                        <TextField
                                            style={{marginInline: "2%", width: "10%"}}
                                            label={t("buildingNr")}
                                            variant={"outlined"}
                                            size={"small"}
                                            value={buildingNr}
                                            onChange={handleBuildingNrChange}
                                        />
                                    </div>
                                    <div style={{display: "flex", marginBottom: "5%"}}>
                                        <TextField
                                            style={{marginInline: "2%"}}
                                            label={t("email")}
                                            variant={"outlined"}
                                            size={"small"}
                                            value={email}
                                            onChange={handleEmailChange}
                                        />
                                        <TextField
                                            style={{marginInline: "2%"}}
                                            label={t("phone")}
                                            variant={"outlined"}
                                            size={"small"}
                                            value={phone}
                                            onChange={handlePhoneChange}
                                        />
                                        <TextField
                                            style={{marginInline: "2%"}}
                                            label={t("mobile")}
                                            variant={"outlined"}
                                            size={"small"}
                                            value={mobile}
                                            onChange={handleMobileChange}
                                        />
                                    </div>
                                    <div>
                                        <Button className={classes.create_button}
                                                endIcon={<CancelOutlined/>}
                                                onClick={cancelAddAddress}
                                                variant={"contained"}>
                                            {t("cancel")}

                                        </Button>
                                        <Button
                                            className={classes.create_button}
                                            endIcon={<AddCircle/>}
                                            onClick={addAddress}
                                            variant={"contained"}>
                                            {t("add")}

                                        </Button>
                                    </div>
                                </Card>
                            }
                        </Grid>
                        <CardActions className={classes.button_container}>
                            <Button
                                disabled={addingAddress}
                                className={classes.cancel_button}
                                endIcon={<CancelOutlined/>}
                                onClick={handleClose}
                                variant={"contained"}>
                                {t("cancel")}
                            </Button>
                            <Button
                                disabled={addingAddress}
                                className={classes.confirm_button}
                                endIcon={<CheckCircleOutlined className={classes.confirm_icon}/>}
                                onClick={handleSubmit}
                                color="primary"
                                variant={"contained"}>
                                {t("confirm")}
                            </Button>
                        </CardActions>
                    </TabPanel>
                    <TabPanel value={'2'}>
                        <MessageTemplatesPage contractId={id ? id : null}/>
                    </TabPanel>
                </TabContext>
            </>
        }
        <Notification open={openNotify} severity={notifySeverity} duration={3500}
                      onClose={closeNotification}>{notificationMessage}</Notification>

    </div>


}

export default ContractForm;