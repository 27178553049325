import {useTranslation} from "react-i18next";
import {Switch, useHistory, useLocation} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead} from "@material-ui/core";
import {PrivateRoute} from "../../routes/PrivateRoute";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import {makeStyles} from "@material-ui/core/styles";
import CustomerPreInvoiceForm from "./CustomerPreInvoiceForm";
import CustomerPreInvoice from "./CustomerPreInvoice";
import {compareAsc} from "date-fns";
import {LoadingComponent} from "../../components/loading/LoadingComponent";
import {SearchOutlined} from "@material-ui/icons";
import AccountService from "../../accounts/AccountService";
import {Autocomplete} from "@mui/material";
import {CircularProgress, TextField} from "@mui/material";
import DateField from "../../components/fields/DateField";
import ContractService from "../../accounts/contracts/ContractService";
import DocumentService from "../documents/DocumentService";
import moment from "moment/moment";
import InfoChip from "../../components/chips/InfoChip";
import Notification from "../../components/notifications/Notification";


function CustomerPreInvoicesTable(props) {

    const [preInvoices, setPreInvoices] = useState([]);
    const {t} = useTranslation();
    const history = useHistory();
    const location = useLocation();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [refreshTable, setRefreshTable] = useState(true);
    const [deleteSuccess, setDeleteSuccess] = useState(false);
    const [customer, setCustomer] = useState(null);
    const [customers, setCustomers] = useState([]);
    const [contract, setContract] = useState(null);
    const [contracts, setContracts] = useState([]);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [number, setNumber] = useState('');

    const [openNotify, setOpenNotify] = useState(false);
    const [notifySeverity, setNotifySeverity] = useState('');
    const [notificationMessage, setNotificationMessage] = useState(null);

    const [fetching, setFetching] = useState(false);
    const [fetched, setFetched] = useState(false);


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    function deleteCallback() {
        setNotificationMessage(t("successful"));
        setNotifySeverity('success');
        setOpenNotify(true);
        search();
        setRefreshTable(!refreshTable);
        setDeleteSuccess(true);

    }

    const useStyles = makeStyles((theme) => ({

        root: {
            width: '100%',
            textAlign: 'center',
            justifyContent: "center",
            display: "block",

        },
        label: {
            ...theme.typography.button,
            backgroundColor: theme.palette.background.paper,
            padding: theme.spacing(1),
        },
        container: {
            width: '100%',
            maxHeight: 840,
        },
        table: {
            width: '80%',
            margin: "auto",
        },
        iconCell: {
            justifyContent: "end",
            margin: "auto",
            textAlign: 'end',
            display: "block",
        },
        button: {
            marginTop: "3%",
            marginBottom: "3%",
        },
        icon: {
            marginRight: "5%"
        },
        idCell: {
            width: "20%"
        },
        field: {
            display: "block",
            marginTop: "1%",
            marginBottom: "1%"
        },
        filterMask: {
            width: "80%",
            margin: "auto",
            marginBottom: "1%",
            border: "2px solid"
        },
        formControl: {
            margin: theme.spacing(1),
            width: "30%",
        },
    }));

    const classes = useStyles();

    async function fetchCustomers() {

        let filters = {};
        filters.isCustomer = true;

        let customers = await new AccountService().getAccounts(filters);
        setCustomers(customers)
    }

    async function fetchContracts() {
        let filters = {}
        filters.accountId = customer.id;

        let contracts = await new ContractService().getContractsByFilters(filters)
        setContracts(contracts)
    }


    function goToPreInvoicesForm() {
        history.push("/customer-pre-invoices/new");
    }

    async function search() {

        setFetching(true);

        let filters = {};

        filters.documentType = "ACTIVE_PREINVOICE"

        if (customer) {
            filters.accountId = customer.id;
        }
        if (contract) {
            filters.contractId = contract.id;
        }
        if (fromDate) {
            filters.fromDate = moment(fromDate).format('yyyy-MM-DD');
        }
        if (toDate) {
            filters.toDate = moment(toDate).format('yyyy-MM-DD');
        }
        if (number !== '') {
            filters.number = number;
        }

        await new DocumentService().getDocumentsByFilters(filters)
            .then(result => {
                setPreInvoices(result.documents);
                setFetching(false);
                setFetched(true);
            })
            .catch(error => {
                error.response.json().then(response => {
                    setNotificationMessage(response.status + "-" + response.message);
                    setNotifySeverity('error');
                    setOpenNotify(true);
                    setFetching(false);
                })
            })

    }

    function handleFirstDate(event) {
        if (toDate == null || compareAsc(event, toDate) === 1) {
            setFromDate(event);
            setToDate(event);
        } else {
            setFromDate(event)
        }
    }

    function handleSecondDate(event) {
        if (fromDate == null || compareAsc(fromDate, event) === 1) {
            setFromDate(event);
            setToDate(event);
        } else {
            setToDate(event);
        }
    }

    function handleCustomerChange(event, customer) {
        setCustomer(customer);
        setContract(null);
    }

    function handleNumberChange(event) {
        let amount = event.target.value;

        if (!(isNaN(amount) || amount < 0 || event.target.value[0] === '0')) {
            setNumber(amount);
        }

    }

    function refresh(){
        search();
    }


    useEffect(() => {

    }, [refreshTable]);


    return <Paper className={classes.root}>

        <Switch>
            <PrivateRoute exact path={"/customer-pre-invoices"}>

                <Button startIcon={<AddCircleIcon/>} variant="contained" color="primary" onClick={goToPreInvoicesForm}
                        className={classes.button}>
                    {t("create_new_pre-invoice")}
                </Button>
                <Notification open={openNotify} severity={notifySeverity} duration={3000}
                              onClose={() => setOpenNotify(false)}>{notificationMessage}</Notification>
                <div className={classes.root}>
                    <Paper elevation={1} className={classes.filterMask}>
                        <div className={classes.label}>{t("filter_by")}</div>
                        <TextField
                            style={{width: "30%", margin: "auto"}}
                            label={t("pre_invoice_number")}
                            variant="outlined"
                            margin="dense"
                            size={"small"}
                            value={number}
                            defaultValue={number}
                            onChange={handleNumberChange}
                        />
                        <Autocomplete
                            id="customer"
                            size="small"
                            style={{marginBottom: "2%", width: "30%", margin: "auto"}}
                            options={customers}
                            getOptionLabel={option => option.accountCorporateCode + " - " + option.companyName}
                            value={customer}
                            defaultValue={customer}
                            onOpen={fetchCustomers}
                            noOptionsText={t("no_options")}
                            onChange={handleCustomerChange}
                            ListboxProps={{style: {maxHeight: '15rem'},}}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t("customer")}
                                    size={"small"}
                                    variant="outlined"
                                    margin="dense"
                                    value={customer}
                                    defaultValue={customer}
                                />
                            )}
                        />
                        <Autocomplete
                            id="contract"
                            size="small"
                            style={{marginBottom: "2%", width: "30%", margin: "auto"}}
                            options={contracts}
                            disabled={!customer}
                            getOptionLabel={option => option.code + " - " + option.name}
                            value={contract}
                            defaultValue={contract}
                            noOptionsText={t("no_options")}
                            onOpen={fetchContracts}
                            onChange={(event, contract) => setContract(contract)}
                            ListboxProps={{style: {maxHeight: '15rem'},}}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    size={"small"}
                                    label={t("contract")}
                                    variant="outlined"
                                    margin="dense"
                                    value={contract}
                                    defaultValue={contract}
                                />
                            )}
                        />
                        <div style={{display: "flex"}}>
                            <DateField
                                style={{margin: "auto", width: "20%"}}
                                label={t("management_from_date")}
                                value={fromDate}
                                onChange={handleFirstDate}
                                format={"dd/MM/yyyy"}
                                disableFuture={true}
                            />
                            <DateField
                                style={{margin: "auto", width: "20%"}}
                                label={t("management_to_date")}
                                value={toDate}
                                onChange={handleSecondDate}
                                format={"dd/MM/yyyy"}
                                disableFuture={true}
                            />
                        </div>


                        <Button startIcon={fetching ? <CircularProgress style={{color: "white"}} size={20}/> : <SearchOutlined/>} className={classes.button} variant="contained"
                                color="primary" type="button" onClick={search}>
                            {t("search")}
                        </Button>

                    </Paper>
                </div>

                {fetching ?
                    <LoadingComponent/>
                    :
                    <>
                        {preInvoices.length > 0 ?
                            <TableContainer className={classes.container}>
                                <Table stickyHeader className={classes.table}>
                                    <TableHead>
                                        <TableCell>{t("progressive")}</TableCell>
                                        <TableCell>{t("contract")}</TableCell>
                                        <TableCell>{t("date")}</TableCell>
                                        <TableCell>{t("total")}</TableCell>
                                        <TableCell>{t("protocol_number")}</TableCell>
                                        <TableCell>{t("invoice_number")}</TableCell>
                                        <TableCell>{t("invoice_date")}</TableCell>
                                        <TableCell>{t("status")}</TableCell>
                                        <TableCell/>
                                    </TableHead>
                                    <TableBody>
                                        {preInvoices.map((preInvoice, index) => {

                                            return <CustomerPreInvoice
                                                key={index}
                                                deleteCallback={deleteCallback}
                                                preInvoice={preInvoice}
                                                refresh={refresh}
                                            />

                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            :
                            <>
                                {fetched ?
                                    <InfoChip>{t("no_data")}</InfoChip>
                                    :
                                    null
                                }
                            </>
                        }
                    </>
                }


            </PrivateRoute>
            <PrivateRoute exact path={`/customer-pre-invoices/new/`}>
                <CustomerPreInvoiceForm preInvoice={null}/>
            </PrivateRoute>
        </Switch>

    </Paper>

}

export default CustomerPreInvoicesTable;

