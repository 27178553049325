import {Backdrop, Button, DialogTitle, Fade, Modal, Paper} from "@material-ui/core";
import {Autocomplete, IconButton, TextField, Typography} from "@mui/material";
import DateField from "../components/fields/DateField";
import CurrencyTextField from "@unicef/material-ui-currency-textfield";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import CompanyService from "../companies/CompanyService";
import BankAccountService from "../bank_accounts/BankAccountService";
import {
    ArrowBackRounded,
    CancelOutlined,
    CancelRounded,
    CheckCircleOutlined,
    PostAdd,
    SaveOutlined
} from "@material-ui/icons";
import PaymentSlipsService from "../cod/payments/PaymentSlipsService";
import AccountService from "../accounts/AccountService";
import ContractService from "../accounts/contracts/ContractService";
import BankTransactionService from "./BankTransactionService";
import moment from "moment";
import {useHistory, useParams} from "react-router-dom";
import theme from "../theme/theme";
import PaymentToCustomerService from "../cod/payments_to_customers/PaymentToCustomerService";
import Notification from "../components/notifications/Notification";
import {LoadingComponent} from "../components/loading/LoadingComponent";
import WarehouseService from "../warehouses/WarehouseService";
import PaymentCardService from "../payment_cards/PaymentCardService";


function BankTransactionForm(props) {

    const {t} = useTranslation();

    const history = useHistory();

    const [openDifferenceNotZeroModal, setOpenDifferenceNotZeroModal] = useState(false);

    const [totalAmount, setTotalAmount] = useState(0);
    const [splitsTotal, setSplitsTotal] = useState(0);
    const [difference, setDifference] = useState(0);

    const [companies, setCompanies] = useState([]);
    const [company, setCompany] = useState(null);

    const [bankAccounts, setBankAccounts] = useState([]);
    const [bankAccount, setBankAccount] = useState(null);

    const [accounts, setAccounts] = useState([]);
    const [account, setAccount] = useState(null);

    const [contracts, setContracts] = useState([]);
    const [contract, setContract] = useState(null);

    const [warehouses, setWarehouses] = useState([]);
    const [warehouse, setWarehouse] = useState(null);

    const [valueDate, setValueDate] = useState(null);
    const [accountingDate, setAccountingDate] = useState(null);

    const [accountNumber, setAccountNumber] = useState('');
    const [controlInternalNumber, setControlInternalNumber] = useState('');
    const [bankCode, setBankCode] = useState('');
    const [branchCode, setBranchCode] = useState('');

    const [reason, setReason] = useState('');
    const [reference, setReference] = useState('');
    const [chequeNumber, setChequeNumber] = useState('');

    const [paymentCards, setPaymentCards] = useState([]);
    const [paymentCard, setPaymentCard] = useState(null);

    const [documentReferences, setDocumentReferences] = useState([]);

    const [refresh, setRefresh] = useState(false);

    let documentTypes = ["payment_slips", "payments_to_customers"]

    const [loading, setLoading] = useState(false);

    const [splits, setSplits] = useState([])

    const [openNotify, setOpenNotify] = useState(false);
    const [notifySeverity, setNotifySeverity] = useState('');
    const [notificationMessage, setNotificationMessage] = useState(null);

    const [saving, setSaving] = useState(false);

    const {id} = useParams();


    async function fetchCards(){

        await new PaymentCardService().getCardsByFilters()
            .then(result => {
                setPaymentCards(result.paymentCards);
            })
            .catch(error => {

            })

    }


    async function fetchWarehouses() {

        let filters = {};
        if (account) {
            filters.accountId = account.id;
        }

        await new WarehouseService().getWarehousesByFilters(null, null, filters)
            .then(result => {
                setWarehouses(result.warehouses);
            })
            .catch(error => {

            })

    }


    async function fetchBankTransactionAndSetData() {

        setLoading(true);

        if (id) {
            await new BankTransactionService().getBankTransactionById(id)
                .then(async response => {

                    let filters = {}

                    filters.accountId = response.accountId;

                    let account = await new AccountService().getById(response.accountId)
                        .then(result => {
                            setAccount(result);
                        })
                        .catch(error => {

                        })

                    if(null != response.warehouseCode){

                        await new WarehouseService().getWarehousesByFilters(null, null, {code: response.warehouseCode})
                            .then(result => {
                                setWarehouse(result.warehouses.find(warehouse => warehouse.code === response.warehouseCode));
                            })
                            .catch(error => {

                            })

                    }


                    await new CompanyService().getCompanies()
                        .then(async result => {
                            let company = result.find(company => company.id === response.companyId);

                            setCompanies(result);
                            setCompany(company);

                            let filters = {};

                            if (company) {
                                filters.companyId = company.id;
                            }

                            await new BankAccountService().getBankAccountsByFilters(filters)
                                .then(result => {
                                    setBankAccounts(result)

                                    setBankAccount(result.find(bankAccount => bankAccount.id === response.bankAccountId))
                                })
                                .catch(error => {
                                    error.response.json().then(response => {
                                        setNotificationMessage(response.status + "-" + response.message);
                                        setNotifySeverity('error');
                                        setOpenNotify(true);
                                    })
                                })
                        })
                        .catch(error => {
                            error.response.json().then(response => {
                                setNotificationMessage(response.status + "-" + response.message);
                                setNotifySeverity('error');
                                setOpenNotify(true);
                            })
                        })

                    setReason(response.reason ? response.reason : "");
                    setAccountingDate(new Date(response.accountingDate));
                    setValueDate(new Date(response.valueDate));
                    setBankCode(response.bankCode ? response.bankCode : "");
                    setControlInternalNumber(response.controlInternalNumber ? response.controlInternalNumber : "");
                    setAccountNumber(response.accountNumber ? response.accountNumber : "");
                    setBranchCode(response.branchCode ? response.branchCode : "");
                    setReference(response.reference ? response.reference : "");
                    setChequeNumber(response.chequeNumber ? response.chequeNumber : "");

                    await new PaymentCardService().matchCardFromString(response.reason)
                        .then(async response => {
                            if(response){
                                setPaymentCard(response);

                                await new AccountService().getAccounts({accountCorporateCode: response.accountCorporateCode})
                                    .then(result => {
                                        setAccount(result[0])
                                    })
                                    .catch(error => {

                                    })

                                await new WarehouseService().getWarehousesByFilters(null, null, {code: response.warehouseCode})
                                    .then(result => {
                                        setWarehouse(result.warehouses[0]);
                                    })
                                    .catch(error => {

                                    })
                            }
                        })
                        .catch(error => {

                        })

                    setTotalAmount(response.amount);
                    setDifference(response.amount);

                    let totalSplits = 0;

                    response.splits.forEach(split => totalSplits += split.amount);
                    setSplitsTotal(totalSplits);

                    setDifference(response.amount - totalSplits);


                    for (let i = 0; i < response.splits.length; i++) {

                        let split = {};

                        split.updatable = response.splits[i].updatable;
                        split.amount = response.splits[i].amount;
                        split.documentType = response.splits[i].paymentSlipId ? "payment_slips" : (response.splits[i].paymentToCustomerId ? "payments_to_customers" : "other");

                        let filters = {};

                        switch (split.documentType) {
                            case "payment_slips":

                                if (account) {
                                    filters.supplierId = account?.id;
                                }

                                await new PaymentSlipsService().getPaymentSlipHeads(filters)
                                    .then(result => {
                                        split.documentReference = result.paymentSlips.find(paymentSlip => paymentSlip.id === response.splits[i].paymentSlipId);
                                    })
                                    .catch(error => {
                                        error.response.json().then(response => {
                                            setNotificationMessage(response.status + "-" + response.message);
                                            setNotifySeverity('error');
                                            setOpenNotify(true);
                                        })
                                    })
                                break;
                            case "payments_to_customers":

                                await new PaymentToCustomerService().getPaymentsByFilters(filters)
                                    .then(result => {
                                        split.documentReference = result.payments.find(payment => payment.id === response.splits[i].paymentToCustomerId);
                                    })
                                    .catch(error => {
                                        error.response.json().then(response => {
                                            setNotificationMessage(response.status + "-" + response.message);
                                            setNotifySeverity('error');
                                            setOpenNotify(true);
                                        })
                                    })
                                break;
                            case "other":

                                split.documentReference = response.splits[i].generalTransactionDescription;

                                break;
                            default:
                                break;
                        }

                        let newSplits = splits;

                        newSplits.push(split);
                        // newSplits = [...newSplits, split]
                        setSplits(newSplits);

                    }

                })
                .catch(error => {

                })

        }

        setLoading(false);

    }


    function handleContractChange(event, contract) {
        setContract(contract);

        if (null != contract && contract.bankAccounts.length > 0) {
            let iban = contract?.bankAccounts?.filter(bankAccount => bankAccount?.type === "COD")[0].bankAccount?.iban;

            if (iban !== null && iban.length === 27) {
                setControlInternalNumber(iban.substr(2, 3));
                setBankCode(iban.substr(5, 5));
                setBranchCode(iban.substr(10, 5));
                setAccountNumber(iban.substr(15, 12));
            }
        } else {
            setControlInternalNumber('');
            setBankCode('');
            setBranchCode('');
            setAccountNumber('');
        }

    }

    function returnDocumentTypes() {

        let types;

        if(splits.find(split => split.documentType === "payment_slips")){
            types = ["payment_slips", "other"]
        }

        else if(splits.find(split => split.documentType === "payments_to_customers")){
            types = ["payments_to_customers", "other"]
        }

        else {
            types = ["payment_slips", "payments_to_customers", "other"]
        }

        return types;

    }

    function addSplit() {

        let newSplits = splits;
        newSplits = [...newSplits, {amount: totalAmount - splitsTotal, documentType: null, documentReference: null, updatable: true}]
        setSplits(newSplits);

        let total = 0;

        for (let i = 0; i < newSplits.length; i++) {
            total += newSplits[i].amount;
        }

        setSplitsTotal(total);
        setDifference(totalAmount - total);

        setRefresh(!refresh);
    }

    function removeSplit(index) {

        if (splits.length > 1) {
            splits.splice(index, 1);
            setRefresh(!refresh);
        }

        let total = 0;

        for (let i = 0; i < splits.length; i++) {
            total += splits[i].amount;
        }

        setSplitsTotal(total);
        setDifference(totalAmount - total);
    }

    async function fetchSuppliers() {

        let filters = {};
        // I want to see both customers and accounts
        //filters.isSupplier = true;

        await new AccountService().getAccounts(filters)
            .then(result => {
                setAccounts(result)
            })
            .catch(error => {
                error.response.json().then(response => {
                    setNotificationMessage(response.status + "-" + response.message);
                    setNotifySeverity('error');
                    setOpenNotify(true);
                })
            })

    }


    async function fetchContracts() {

        let filters = {};

        filters.accountId = account?.id;

        await new ContractService().getContractsByFilters(filters)
            .then(result => {
                setContracts(result);
            })
            .catch(error => {
                error.response.json().then(response => {
                    setNotificationMessage(response.status + "-" + response.message);
                    setNotifySeverity('error');
                    setOpenNotify(true);
                })
            })


    }


    async function fetchByType(type) {

        let filters = {};

        switch (type) {
            case "payment_slips":

                filters.isFullyPaid = false;

                if (account) {
                    filters.supplierId = account?.id;
                }

                if(warehouse){
                    filters.warehouseId = warehouse?.id;
                }


                await new PaymentSlipsService().getPaymentSlipHeads(filters)
                    .then(result => {
                        setDocumentReferences(result.paymentSlips);
                        return result.paymentSlips;
                    })
                    .catch(error => {
                        error.response.json().then(response => {
                            setNotificationMessage(response.status + "-" + response.message);
                            setNotifySeverity('error');
                            setOpenNotify(true);
                        })
                    })
                break;
            case "payments_to_customers":

                filters.isFullyPaid = false;
                if (account) {
                    filters.accountId = account?.id;
                }

                await new PaymentToCustomerService().getPaymentsByFilters(filters)
                    .then(result => {
                        setDocumentReferences(result.payments);
                        return result.payments;
                    })
                    .catch(error => {
                        error.response.json().then(response => {
                            setNotificationMessage(response.status + "-" + response.message);
                            setNotifySeverity('error');
                            setOpenNotify(true);
                        })
                    })
                break;
            default:
                break;
        }

    }

    async function fetchCompanies() {


        await new CompanyService().getCompanies()
            .then(result => {
                setCompanies(result);
            })
            .catch(error => {
                error.response.json().then(response => {
                    setNotificationMessage(response.status + "-" + response.message);
                    setNotifySeverity('error');
                    setOpenNotify(true);
                })
            })

    }

    async function fetchBankAccounts() {

        let filters = {};

        if (company) {
            filters.companyId = company.id;
        }

        await new BankAccountService().getBankAccountsByFilters(filters)
            .then(result => {
                setBankAccounts(result)
            })
            .catch(error => {
                error.response.json().then(response => {
                    setNotificationMessage(response.status + "-" + response.message);
                    setNotifySeverity('error');
                    setOpenNotify(true);
                })
            })

    }

    function clearAll() {
        setCompany(null);
        setBankAccount(null);
        setSplits([{amount: 0, documentType: null, documentReference: null}]);
        setAccount(null);
        setContract(null);
        setAccountingDate(null);
        setValueDate(null);
        setReason('');
        setAccountNumber('')
        setBankCode('')
        setControlInternalNumber('')
        setBranchCode('')
        setReference('');
        setChequeNumber('');
    }

    async function saveTransaction() {

        setSaving(true)

        let input = {};

        input.valueDate = moment(valueDate).format("yyyy-MM-DD");
        input.accountingDate = moment(accountingDate).format("yyyy-MM-DD");
        input.controlInternalNumber = controlInternalNumber;
        input.bankCode = bankCode;
        input.branchCode = branchCode;
        input.accountNumber = accountNumber;
        input.reason = reason;
        input.reference = reference;
        input.chequeNumber = chequeNumber;
        input.bankAccountId = bankAccount?.id;
        input.accountId = account?.id;
        input.paymentCardId = paymentCard?.id;
        input.amount = totalAmount.toFixed(2);

        input.splits = [];

        for (let i = 0; i < splits.length; i++) {
            let payment = {};

            payment.amount = splits[i].amount.toFixed(2);
            if (null != splits[i].documentReference) {
                switch (splits[i].documentType) {
                    case "payment_slips":
                        payment.paymentSlipId = splits[i].documentReference.id;
                        break;
                    case "payments_to_customers":
                        payment.paymentToCustomerId = splits[i].documentReference.id;
                        break;
                    case "other":
                        let generalTransaction = {};
                        generalTransaction.totalAmount = splits[i].amount.toFixed(2);
                        generalTransaction.description = splits[i].documentReference;

                        payment.generalTransaction = generalTransaction;
                        break;
                    default:
                        break;
                }
            }

            input.splits.push(payment);
        }

        let inputs = [];
        inputs.push(input);

        if (id) {
            await new BankTransactionService().updateTransaction(id, input)
                .then(result => {
                    setNotifySeverity('success');
                    setNotificationMessage(t("successful"));
                    setOpenNotify(true);
                    setSaving(false);
                    setTimeout(() => {
                        history.push("/bank-transactions")
                    }, 1000)

                })
                .catch(error => {
                    error.response.json().then(response => {
                        setNotificationMessage(response.status + "-" + response.message);
                        setNotifySeverity('error');
                        setOpenNotify(true);
                        setSaving(false);
                    })
                })
        } else {
            await new BankTransactionService().saveTransaction(inputs)
                .then(result => {
                    setNotifySeverity('success');
                    setNotificationMessage(t("successful"));
                    setOpenNotify(true);
                    setSaving(false);
                    setTimeout(() => {
                        history.push("/bank-transactions")
                    }, 1000)
                })
                .catch(error => {
                    error.response.json().then(response => {
                        setNotificationMessage(response.status + "-" + response.message);
                        setNotifySeverity('error');
                        setOpenNotify(true);
                        setSaving(false);
                    })
                })
        }


    }

    function goBack() {
        history.goBack();
    }

    function getValueOfDocument(value, type, index) {

        if (value !== null) {
            switch (type) {
                case "payment_slips" : {

                    let total = 0;

                    if(null != value.bankTransactions){
                        for (let i = 0; i < value.bankTransactions.length; i++) {

                            if(value.bankTransactions[i].transactionId !== id){
                                total += value.bankTransactions[i].amount;
                            }

                        }
                    }

                    for (let i = 0; i < splits.length; i++) {
                        if (splits[i].documentReference?.id === value?.id && i !== index) {
                            total += splits[i].amount;
                        }
                    }

                    let documentAmount = value?.totalPaid.toFixed(2) - total.toFixed(2) || 0;

                    if(documentAmount > difference){
                        documentAmount = difference
                    }


                    return documentAmount;
                }
                case "payments_to_customers": {

                    let total = 0;

                    if(null != value.bankTransactions){
                        for (let i = 0; i < value.bankTransactions.length; i++) {

                            if(value.bankTransactions[i].transactionId !== id){
                                total += value.bankTransactions[i].amount;
                            }

                        }
                    }



                    for (let i = 0; i < splits.length; i++) {
                        if (splits[i].documentReference?.id === value?.id && i !== index) {
                            total += splits[i].amount;
                        }
                    }

                    let documentAmount = -value?.totalAmount.toFixed(2) - total.toFixed(2) || 0;

                    if(documentAmount < difference){
                        documentAmount = difference
                    }


                    return documentAmount;
                }
                default:
                    break;
            }
        } else {
            return 0;
        }

    }

    function getOptionLabelOf(option, type) {


        switch (type) {
            case "payment_slips" : {
                return t("number") + option.id + " - " + option.warehouseCode + " - " + option.currency + " " + option.totalPaid + " " + t("of") + new Date(option.dateTime).toLocaleDateString();
            }
            case "payments_to_customers": {
                return t("number") + option.id + " - " + option.contract?.code + " - " + option.currency + " " + option.totalAmount + " " + t("of") + new Date(option.dateTime).toLocaleDateString();
            }
            default:
                break;
        }

    }

    useEffect(() => {

    }, [refresh])

    useEffect(() => {
        fetchBankTransactionAndSetData();
    }, [])


    return <div>
        <div style={{display: "flex"}}>
            <Button
                style={{margin: "2%", background: "transparent"}}
                onClick={goBack}
                startIcon={<ArrowBackRounded/>}
                variant={"filled"}
            >
                {t("back")}
            </Button>

        </div>

        {loading ?
            <LoadingComponent/>
            :
            <Paper>
                <DialogTitle style={{width: "fit-content", margin: "auto"}}>{t("transaction")}</DialogTitle>
                <div>
                    <Autocomplete
                        id="companies"
                        size="small"
                        style={{width: "30%", margin: "auto"}}
                        options={companies}
                        getOptionLabel={option => option.companyName}
                        value={company}
                        defaultValue={company}
                        noOptionsText={t("no_options")}
                        onOpen={fetchCompanies}
                        onChange={(event, value) => {
                            setCompany(value);
                        }}
                        ListboxProps={{style: {maxHeight: '15rem'},}}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={t("company")}
                                variant="outlined"
                                margin="dense"
                                value={company}
                                defaultValue={company}
                                required
                                error={!company}
                            />
                        )}
                    />
                    <Autocomplete
                        id="bank-account"
                        size="small"
                        disabled={!company}
                        options={bankAccounts}
                        style={{width: "30%", margin: "auto"}}
                        getOptionLabel={option => option.bankName + " - " + option.iban}
                        value={bankAccount}
                        defaultValue={bankAccount}
                        noOptionsText={t("no_options")}
                        onOpen={fetchBankAccounts}
                        onChange={(event, value) => {
                            setBankAccount(value);
                        }}
                        ListboxProps={{style: {maxHeight: '15rem'},}}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={t("bank_account")}
                                variant="outlined"
                                margin="dense"
                                value={bankAccount}
                                defaultValue={bankAccount}
                                required
                                error={!bankAccount}
                            />
                        )}
                    />
                </div>
                <div style={{width: "100%", display: "flex"}}>
                    <DateField
                        style={{width: "25%", margin: "auto"}}
                        label={t("value_date")}
                        value={valueDate}
                        onChange={event => {
                            setValueDate(event)
                        }}
                        required={true}
                        error={!valueDate}
                        format={"dd/MM/yyyy"}
                        disableFuture={true}
                        clearable={false}
                    />
                    <DateField
                        style={{width: "25%", margin: "auto"}}
                        label={t("accounting_date")}
                        value={accountingDate}
                        onChange={event => {
                            setAccountingDate(event)
                        }}
                        required={true}
                        error={!accountingDate}
                        format={"dd/MM/yyyy"}
                        disableFuture={true}
                        clearable={false}
                    />
                </div>
                <div>
                    <Autocomplete
                        id="suppliers"
                        size="small"
                        options={accounts}
                        style={{width: "30%", margin: "auto"}}
                        getOptionLabel={option => option.accountCorporateCode + " - " + option.companyName}
                        value={account}
                        defaultValue={account}
                        noOptionsText={t("no_options")}
                        onOpen={fetchSuppliers}
                        onChange={(event, value) => {
                            setAccount(value);
                            //if (null == value) {
                            setContract(null);
                            setAccountNumber('');
                            setBankCode('');
                            setControlInternalNumber('');
                            setBranchCode('');
                            //}
                        }}
                        ListboxProps={{style: {maxHeight: '15rem'},}}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={t("account")}
                                variant="outlined"
                                margin="dense"
                                value={account}
                                defaultValue={account}
                            />
                        )}
                    />
                    {!account || !account.supplier ?
                        null
                        :
                        <Autocomplete
                            id="suppliers"
                            size="small"
                            disabled={!account || !account.supplier}
                            options={warehouses}
                            style={{width: "30%", margin: "auto"}}
                            getOptionLabel={option => option.code + " - " + option.name}
                            value={warehouse}
                            defaultValue={warehouse}
                            noOptionsText={t("no_options")}
                            onOpen={fetchWarehouses}
                            onChange={(event, value) => {
                                setWarehouse(value)
                                //}
                            }}
                            ListboxProps={{style: {maxHeight: '15rem'},}}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t("warehouse")}
                                    variant="outlined"
                                    margin="dense"
                                    value={warehouse}
                                    defaultValue={warehouse}
                                />
                            )}
                        />
                    }
                    <Autocomplete
                        id="contracts"
                        size="small"
                        disabled={!account}
                        options={contracts}
                        style={{width: "30%", margin: "auto"}}
                        getOptionLabel={option => option.code + " - " + option.name}
                        value={contract}
                        defaultValue={contract}
                        noOptionsText={t("no_options")}
                        onOpen={fetchContracts}
                        onChange={handleContractChange}
                        ListboxProps={{style: {maxHeight: '15rem'},}}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={t("contract")}
                                variant="outlined"
                                margin="dense"
                                value={contract}
                                defaultValue={contract}
                            />
                        )}
                    />
                </div>
                <div style={{marginTop: "2%", display: "flex"}}>
                    <TextField
                        style={{width: "30%", margin: "auto"}}
                        variant={"outlined"}
                        size={"small"}
                        label={t("sender_account_number")}
                        value={accountNumber}
                        defaultValue={accountNumber}
                        onChange={event => {
                            setAccountNumber(event.target.value)
                        }}
                    />
                </div>
                <div style={{marginTop: "4%", display: "flex"}}>
                    <TextField
                        style={{width: "30%", margin: "auto"}}
                        variant={"outlined"}
                        size={"small"}
                        label={t("sender_cin")}
                        value={controlInternalNumber}
                        defaultValue={controlInternalNumber}
                        onChange={event => {
                            setControlInternalNumber(event.target.value)
                        }}
                    />
                    <TextField
                        style={{width: "30%", margin: "auto"}}
                        variant={"outlined"}
                        size={"small"}
                        label={t("sender_bank_code")}
                        value={bankCode}
                        defaultValue={bankCode}
                        onChange={event => {
                            setBankCode(event.target.value);
                        }}
                    />
                    <TextField
                        style={{width: "30%", margin: "auto"}}
                        variant={"outlined"}
                        size={"small"}
                        label={t("sender_branch_code")}
                        value={branchCode}
                        defaultValue={branchCode}
                        onChange={event => {
                            setBranchCode(event.target.value)
                        }}
                    />
                </div>
                <div style={{marginTop: "4%", display: "flex"}}>
                    <TextField
                        style={{width: "25%", margin: "auto"}}
                        variant={"outlined"}
                        size={"small"}
                        label={t("transaction_reference")}
                        value={reference}
                        defaultValue={reference}
                        onChange={event => {
                            setReference(event.target.value);
                        }}
                    />
                    <TextField
                        style={{width: "25%", margin: "auto"}}
                        variant={"outlined"}
                        size={"small"}
                        label={t("cheque_number")}
                        value={chequeNumber}
                        defaultValue={chequeNumber}
                        onChange={event => {
                            setChequeNumber(event.target.value)
                        }}
                    />
                </div>
                <div style={{marginTop: "4%", display: "flex"}}>
                    <TextField
                        style={{width: "60%", margin: "auto"}}
                        multiline
                        minRows={1}
                        variant={"outlined"}
                        size={"small"}
                        label={t("reason")}
                        value={reason}
                        defaultValue={reason}
                        onChange={event => {
                            setReason(event.target.value)
                        }}
                        required={true}
                        error={!reason}
                    />
                </div>
                <div style={{marginTop: "4%", display: "flex"}}>
                    <Autocomplete
                        id="cards"
                        size="small"
                        options={paymentCards}
                        style={{width: "50%", margin: "auto"}}
                        getOptionLabel={option => t("payment_card") + " " + t("number") + " " + option.cardNumber + " - " + option.accountCorporateCode + " - " + option.warehouseCode}
                        value={paymentCard}
                        defaultValue={paymentCard}
                        noOptionsText={t("no_options")}
                        onOpen={fetchCards}
                        onChange={(event, card) => setPaymentCard(card)}
                        ListboxProps={{style: {maxHeight: '15rem'},}}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={t("payment_card")}
                                variant="outlined"
                                margin="dense"
                                value={paymentCard}
                                defaultValue={paymentCard}
                            />
                        )}
                    />
                </div>

                <div style={{width: "90%", textAlign: "right", margin: "auto", marginTop: "5%"}}>
                    <CurrencyTextField
                        style={{width: "20%", margin: "auto"}}
                        label={t("total")}
                        variant={"outlined"}
                        margin={"dense"}
                        digitGroupSeparator={"."}
                        decimalCharacter={","}
                        decimalPlaces={2}
                        value={totalAmount}
                        currencySymbol={"€"}
                        onChange={(event, value) => {
                            setTotalAmount(value)

                            setDifference(value - splitsTotal);

                        }}
                    />
                </div>

                <div style={{display: "flex", marginTop: "2%"}}>
                    <Button startIcon={<PostAdd/>} style={{width: "fit-content", margin: "auto"}}
                            onClick={addSplit}>{t("add_split")}</Button>
                </div>


                {splits.map((split, index) => {
                    return <div style={{marginTop: "2%", display: "flex", marginBottom: "2%"}}>
                        <Autocomplete
                            id="document-type"
                            size="small"
                            disabled={!split.updatable}
                            options={returnDocumentTypes()}
                            style={{width: "30%", margin: "auto"}}
                            getOptionLabel={option => t(option)}
                            value={split.documentType}
                            defaultValue={split.documentType}
                            noOptionsText={t("no_options")}
                            onChange={(event, value) => {
                                let newSplit = [...splits];
                                newSplit[index]["documentType"] = value;
                                newSplit[index]["documentReference"] = null;
                                newSplit[index]["amount"] = value === "other"? split.amount : 0;
                                setSplits(newSplit);

                                let total = 0;

                                for (let i = 0; i < splits.length; i++) {
                                    total += splits[i].amount;
                                }

                                setSplitsTotal(total);
                                setDifference(totalAmount - total);

                            }}
                            ListboxProps={{style: {maxHeight: '15rem'},}}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t("document_type")}
                                    variant="outlined"
                                    margin="dense"
                                    value={split.documentType}
                                    defaultValue={split.documentType}
                                />
                            )}
                        />
                        {split.documentType !== "other" ?
                            <Autocomplete
                                id="document-reference"
                                size="small"
                                disabled={!split.documentType || !split.updatable}
                                options={documentReferences}
                                style={{width: "30%", margin: "auto"}}
                                getOptionLabel={option => getOptionLabelOf(option, split.documentType)}
                                value={split.documentReference}
                                defaultValue={split.documentReference}
                                noOptionsText={t("no_options")}
                                onOpen={() => fetchByType(split.documentType)}
                                onChange={(event, value) => {
                                    let newSplit = [...splits];
                                    newSplit[index]["documentReference"] = value;
                                    newSplit[index]["amount"] = getValueOfDocument(value, split.documentType, index);
                                    setSplits(newSplit);

                                    let total = 0;

                                    for (let i = 0; i < newSplit.length; i++) {
                                        total += newSplit[i].amount;
                                    }

                                    setSplitsTotal(total);
                                    setDifference(totalAmount - total);
                                }}
                                ListboxProps={{style: {maxHeight: '15rem'},}}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={t("document_reference")}
                                        variant="outlined"
                                        margin="dense"
                                        value={split.documentReference}
                                        defaultValue={split.documentReference}
                                    />
                                )}
                            />
                            :
                            <TextField
                                disabled={!split.updatable}
                                multiline
                                style={{width: "30%", margin: "auto"}}
                                variant={"outlined"}
                                size={"small"}
                                label={t("description")}
                                value={split.documentReference}
                                defaultValue={split.documentReference}
                                onChange={event => {
                                    let newSplit = [...splits];
                                    newSplit[index]["documentReference"] = event.target.value;
                                    setSplits(newSplit);
                                }}
                                required
                                error={!split.documentReference}
                            />
                        }
                        <CurrencyTextField
                            disabled={!split.updatable}
                            style={{width: "20%", margin: "auto"}}
                            label={t("amount")}
                            variant={"outlined"}
                            margin={"dense"}
                            digitGroupSeparator={"."}
                            decimalCharacter={","}
                            decimalPlaces={2}
                            value={split.amount}
                            currencySymbol={"€"}
                            onChange={(event, value) => {
                                let newSplit = [...splits];
                                newSplit[index]["amount"] = value;
                                setSplits(newSplit);

                                let total = 0;

                                for (let i = 0; i < newSplit.length; i++) {
                                    total += newSplit[i].amount;
                                }

                                setSplitsTotal(total);
                                setDifference(totalAmount - total);

                            }}
                            required
                            error={!split.amount}
                        />
                        <IconButton disabled={!split.updatable} style={{background: 'none'}}
                                    onClick={() => removeSplit(index)}><CancelRounded/></IconButton>
                    </div>

                })}

                <div style={{width: "90%", textAlign: "right", margin: "auto"}}>
                    <CurrencyTextField digitGroupSeparator={"."}
                                       decimalCharacter={","}
                                       decimalPlaces={2}
                                       currencySymbol={"€"}
                                       label={t("splits_total")}
                                       disabled={true}
                                       value={splitsTotal}
                    />
                </div>
                <div style={{width: "90%", textAlign: "right", margin: "auto"}}>
                    <CurrencyTextField digitGroupSeparator={"."}
                                       decimalCharacter={","}
                                       decimalPlaces={2}
                                       currencySymbol={"€"}
                                       label={t("difference")}
                                       disabled={true}
                                       value={difference}
                    />
                </div>

                <div style={{display: "flex", marginTop: "2%", marginBottom: "2%"}}>
                    <Button endIcon={<SaveOutlined/>} style={{
                        width: "fit-content",
                        margin: "auto",
                        marginBlock: "5%",
                        backgroundColor: theme.palette.primary.main,
                        color: "white"
                    }}
                            onClick={difference !== 0? ()=>setOpenDifferenceNotZeroModal(true) : saveTransaction}>{t("save")}</Button>
                </div>
                <Notification open={openNotify} severity={notifySeverity} duration={3000}
                              onClose={() => setOpenNotify(false)}>{notificationMessage}</Notification>
                <Modal open={openDifferenceNotZeroModal}
                    // className={classes.modal}
                       onClose={() => setOpenDifferenceNotZeroModal(false)}
                       closeAfterTransition
                       BackdropComponent={Backdrop}
                       BackdropProps={{
                           timeout: 500,
                       }}
                >
                    <Fade in={openDifferenceNotZeroModal}>
                        <Paper variant={"outlined"} elevation={2} style={{
                            width: "30%",
                            marginInline: "auto",
                            marginTop: "20%",
                            height: "20%",
                            overflow: "hidden",
                            // border: `1px solid black`
                        }}>
                            <div style={{width: "60%", margin: "auto", marginTop: "10%", display: "flex"}}>
                                {/*<WarningOutlined color={"warning"}/>*/}
                                <Typography
                                    style={{margin: "auto"}}>{t("difference_not_zero_message")}</Typography>
                                {/*<WarningOutlined color={"warning"}/>*/}
                            </div>
                            <div style={{
                                display: "flex",
                                alignItems: 'center',
                                justifyContent: 'center',
                                marginBlock: "4%",
                                paddingBottom: "2%"
                            }}>
                                <Button
                                    style={{
                                        marginInline: "4%",
                                        width: "25%"
                                    }}
                                    endIcon={<CancelOutlined/>}
                                    onClick={() => setOpenDifferenceNotZeroModal(false)}
                                    variant={"contained"}>
                                    {t("cancel")}
                                </Button>
                                <Button
                                    style={{
                                        marginInline: "4%",
                                        width: "25%"
                                    }}
                                    endIcon={<CheckCircleOutlined/>}
                                    onClick={saveTransaction}
                                    color="primary"
                                    variant={"contained"}>
                                    {t("confirm")}
                                </Button>
                            </div>
                        </Paper>
                    </Fade>

                </Modal>
            </Paper>
        }
    </div>


}

export default BankTransactionForm;