import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Backdrop, Button, CardActions, DialogTitle, Fade, Modal, Paper, TextField} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {LoadingComponent} from "../components/loading/LoadingComponent";
import {useHistory, useLocation, useParams} from "react-router-dom";
import Notification from "../components/notifications/Notification";
import RateService from "./RateService";
import {Autocomplete, Card, CardContent, Typography} from "@mui/material";
import {AddOutlined, CancelOutlined, CheckCircleOutlined} from "@material-ui/icons";
import CurrencyService from "../currencies/CurrencyService";
import ServiceService from "../shipments/services/ServiceService";
import RateServiceBlockForm from "./RateServiceBlockForm";
import {ContentCopyOutlined, CopyAllOutlined} from "@mui/icons-material";
import theme from "../theme/theme";
import {generateUUID} from "../utils/utils";
import CurrencyTextField from "@unicef/material-ui-currency-textfield";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    field: {
        display: "flex",
        margin: "auto",
        marginBottom: "2%",
        marginTop: "2%",
        width: '25%',
    },
    postal_field: {
        display: "flex",
        margin: "auto",
        marginBottom: "2%",
        marginTop: "2%",
        width: '40%',
    },
    label: {
        width: "15%",
        margin: "auto",
        display: "flex"
    },
    formControl: {
        margin: theme.spacing(1),
        width: "30%",
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    noLabel: {
        marginTop: theme.spacing(3),
    },
    button: {
        display: "block",
        margin: "auto"
    },
    option: {
        backgroundColor: 'white',
        fontSize: 15,
        '& > span': {
            marginRight: 10,
            fontSize: 18,
        },
        icon: {
            color: theme.palette.text.secondary,
            marginRight: theme.spacing(2),
        },
    },
    button_container: {
        display: "flex",
        alignItems: 'center',
        justifyContent: 'center'
    },
    add_service_button: {
        margin: "auto",
        marginBlock: "3%",
        width: "15%",
        background: 'rgba(186,186,186,0.84)',

        color: "#000000",
        transition: "all 0.2s",
        // border: "2px solid green",

        "&:hover": {
            backgroundColor: "#d5d5d5",
        },
    },


    cancel_button: {
        marginInline: "4%",

        width: "15%",
        background: 'rgba(186,186,186,0.84)',

        color: "#000000",
        transition: "all 0.2s",
        // border: "2px solid green",

        "&:hover": {
            backgroundColor: "#d5d5d5",
        },
    },
    confirm_button: {
        marginInline: "4%",

        width: "15%",
        backgroundColor: "green",
        color: "white",
        transition: "all 0.2s",
        // border: "2px solid green",

        "&:hover": {
            background: 'rgba(24,147,0,0.84)',
            // color: "black",
            // border:  "2px solid green",

            "& $confirm_icon": {
                // color: "green"
            }
        },
    },
    confirm_icon: {
        color: "white",
        transition: "all 0.3s",
    }

}));

function RateForm(props) {
    const [refresh, setRefresh] = useState(false);
    const {t} = useTranslation();
    const location = useLocation();
    const history = useHistory();

    //const [fetched, setFetched] = useState(false)

    const {id} = useParams();

    const [loading, setLoading] = useState(false);
    const [rate, setRate] = useState({serviceBlocks: []});
    const [services, setServices] = useState([]);
    const [showModalNewServiceCode, setShowModalNewServiceCode] = useState(false);
    const [showModalDuplicateServiceCode, setShowModalDuplicateServiceCode] = useState(false);

    const [sourceService, setSourceService] = useState(null);
    const [targetService, setTargetService] = useState(null);

    const [mapCurrenciesByCode, setMapCurrenciesByCode] = useState({});
    const [defaultCurrency, setDefaultCurrency] = useState(null);

    const [openNotify, setOpenNotify] = useState(false);
    const [notifySeverity, setNotifySeverity] = useState('');
    const [notificationMessage, setNotificationMessage] = useState(null);

    const classes = useStyles();

    function handleSourceServiceChange(event, service){
        setSourceService(service)
    }

    function handleTargetServiceChange(event, service){
        setTargetService(service)
    }

    async function fetchRate(id) {
        setLoading(true);

        await new RateService().getById(id)
            .then(restRate => {
                const newRate = {...restRate};
                if (newRate.services) {
                    newRate.serviceBlocks = {};
                    for (let i = 0; i < newRate.services.length; ++i) {
                        if (!newRate.serviceBlocks[newRate.services[i].serviceCode]) {
                            newRate.serviceBlocks[newRate.services[i].serviceCode] = {
                                serviceCode: newRate.services[i].serviceCode,
                                services: []
                            };
                        }

                        newRate.services[i].indexOrder = newRate.serviceBlocks[newRate.services[i].serviceCode].services.length+1;
                        newRate.serviceBlocks[newRate.services[i].serviceCode].services.push(newRate.services[i]);
                    }
                }
                delete newRate.services;

                setRate(newRate);
                console.log(newRate)
                //setFetched(true);
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
            })


    }

    async function fetchCurrencies() {
        const currencyService = new CurrencyService();
        const currencies = await currencyService.getAll();

        const map = {};
        let defCurrency;
        for (let i = 0; i < currencies.length; ++i) {
            map[currencies[i].code] = currencies[i];

            if (currencies[i].default) {
                defCurrency = currencies[i];
            }
        }

        setDefaultCurrency(defCurrency);
        setMapCurrenciesByCode(map);
    }

    function handleClose() {
        history.push('/rates');
    }

    function handleSubmit(event) {
        event.preventDefault();

        const restRate = {
            code: rate.code,
            currencyCode: rate.currencyCode ? rate.currencyCode : defaultCurrency.code,
            freightPercentageNewAttempt: rate.freightPercentageNewAttempt,
            fixedFeeNewAttempt: rate.fixedFeeNewAttempt,
            freightPercentageAddressChange: rate.freightPercentageAddressChange,
            fixedFeeAddressChange: rate.fixedFeeAddressChange,
            freightPercentageReturnToSender: rate.freightPercentageReturnToSender,
            fixedFeeReturnToSender: rate.fixedFeeReturnToSender,
            services: []
        };
        if (id) {
            restRate.id = id;
        }

        if (rate.serviceBlocks) {
            const services = Object.keys(rate.serviceBlocks);
            for (let i = 0; i < services.length; ++i) {
                const rateServiceBlock = rate.serviceBlocks[services[i]];
                for (let j = 0; j < rateServiceBlock.services.length; ++j) {
                    let restService = rateServiceBlock.services[j];
                    restService.serviceCode = services[i];
                    restRate.services.push(restService);
                }
            }
        }

        const rateService = new RateService();
        rateService.save(restRate)
            .then(() => {
                setNotifySeverity('success');
                setNotificationMessage(t("successful"));
                setOpenNotify(true);

                setTimeout(() => {
                    history.push({
                        pathname: '/rates',
                        state: {detail: ''}
                    });
                }, 2000)
            })
            .catch((err) => {
                err.response.json().then((response) => {
                    setNotifySeverity('error');
                    setNotificationMessage(response.message);
                    setOpenNotify(true);
                });
            });
    }

    function handleCodeChange(event) {
        const newRate = {...rate, code: event.target.value};
        setRate(newRate);
    }

    function handleCurrencyChange(event, currency) {
        const newRate = {...rate, currencyCode: currency.code};
        setRate(newRate);
    }

    function onChangeFreightPercentageNewAttempt(event, value){
        const newRate = {...rate, freightPercentageNewAttempt: value};
        setRate(newRate);
    }

    function onChangeFixedFeeNewAttempt(event, value){
        const newRate = {...rate, fixedFeeNewAttempt: value};
        setRate(newRate);
    }

    function onChangeFreightPercentageAddressChange(event, value){
        const newRate = {...rate, freightPercentageAddressChange: value};
        setRate(newRate);
    }

    function onChangeFixedFeeAddressChange(event, value){
        const newRate = {...rate, fixedFeeAddressChange: value};
        setRate(newRate);
    }

    function onChangeFreightPercentageReturnToSender(event, value){
        const newRate = {...rate, freightPercentageReturnToSender: value};
        setRate(newRate);
    }

    function onChangeFixedFeeReturnToSender(event, value){
        const newRate = {...rate, fixedFeeReturnToSender: value};
        setRate(newRate);
    }


    function closeNotification() {
        setOpenNotify(false);
    }

    function closeModalNewServiceCode() {
        setShowModalNewServiceCode(false);
    }

    function handleServiceCodeChange(event, service) {
        closeModalNewServiceCode();
        addRateServiceBlock(service.code);
    }

    function addRateServiceBlock(serviceCode) {
        const newMapRateServiceBlocks = {...rate.serviceBlocks};
        if (!newMapRateServiceBlocks[serviceCode]) {
            newMapRateServiceBlocks[serviceCode] = {serviceCode: serviceCode, services: []};
        }

        const newService = {
            uuid: generateUUID(),
            indexOrder: newMapRateServiceBlocks[serviceCode].services.length +1,
            consolidation: "NO_CONSOLIDATION",
            serviceCode: serviceCode
        };
        newMapRateServiceBlocks[serviceCode].services.push(newService);

        const newRate = {...rate, serviceBlocks: newMapRateServiceBlocks};
        setRate(newRate);
    }


    function duplicateRateServiceBlock(){

        if(null == sourceService || sourceService.code === "HAULING"){
            setNotificationMessage("Servizio HAULING non valido per la duplicazione");
            setNotifySeverity("warning");
            setOpenNotify(true);
            return;
        }

        if(null == targetService || targetService.code === "HAULING"){
            setNotificationMessage("Servizio HAULING non valido per la duplicazione");
            setNotifySeverity("warning");
            setOpenNotify(true);
            return;
        }

        const newMapRateServiceBlocks = {...rate.serviceBlocks};

        let services = [];

        let indexOrd = (newMapRateServiceBlocks[targetService.code]?.services?.length || 0) + 1;

        newMapRateServiceBlocks[sourceService.code].services.map(service => {
            let newService = {...service};

            newService.serviceCode = targetService.code;
            newService.indexOrder = indexOrd
            newService.uuid = generateUUID();

            services.push(newService);
            indexOrd++;

        })

        if (!newMapRateServiceBlocks[targetService.code]) {

            newMapRateServiceBlocks[targetService.code] = {serviceCode: targetService.code, services: services};
        } else {
            for (let i = 0; i < services.length; i++) {
                newMapRateServiceBlocks[targetService.code].services.push(services[i]);
            }
        }

        const newRate = {...rate, serviceBlocks: newMapRateServiceBlocks};
        setRate(newRate);

        setShowModalDuplicateServiceCode(false);

    }

    function removeBlock(serviceCode) {
        const mapRateServiceBlocks = {...rate.serviceBlocks};

        console.log(mapRateServiceBlocks);

        delete mapRateServiceBlocks[serviceCode];

        console.log(mapRateServiceBlocks);
        //
        // // const newMapRateServiceBlocks = mapRateServiceBlocks.filter(function (rateServiceBlock) {
        // //     return rateServiceBlock.serviceCode !== serviceCode
        // // });
        // // mapRateServiceBlocks.splice(mapRateServiceBlocks.indexOf(mapRateServiceBlocks[serviceCode]), 1);
        //
        const newRate = {...rate, serviceBlocks: mapRateServiceBlocks};
        setRate(newRate);

        setRefresh(!refresh);
    }

    function onRateServiceBlockChange(index, rateServiceBlock) {
        const newMapRateServiceBlocks = {...rate.serviceBlocks};
        newMapRateServiceBlocks[rateServiceBlock.serviceCode] = rateServiceBlock;

        const newRate = {...rate, serviceBlocks: newMapRateServiceBlocks};
        setRate(newRate);
    }

    function getAvailableRateServiceBlockServices() {
        const newServices = [];
        for (let i = 0; i < services.length; ++i) {
            if (rate.serviceBlocks[services[i].code]) {
                newServices.push(services[i]);
            }
        }

        return newServices;
    }

    useEffect(() => {
        fetchCurrencies();
        // if (id && !fetched) {
        //     fetchRate(id);
        // }
    }, [refresh, location]);

    useEffect(() => {
        async function fetchServices() {
            const serviceService = new ServiceService();
            const restServices = await serviceService.getServicesByFilters({
                deliveryServices: true,
                pickupServices: true,
                haulingServices: true,
                handlingServices: true
            });
            setServices(restServices);
        }

        fetchServices();

        if (id) {
            fetchRate(id);
        }
    }, []);

    return <div>
        {/*<DialogTitle>{t("rates")}</DialogTitle>*/}
        {loading ?

            <LoadingComponent/>

            :

            <div>
                <Card className={classes.root}>
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                            {t("rate")}
                        </Typography>

                        <TextField
                            required
                            label={t("code")}
                            variant="outlined"
                            type="text"
                            margin="dense"
                            onChange={handleCodeChange}
                            value={rate.code || ""}
                            className={classes.field}
                        />
                        <Autocomplete
                            id="currencyCode"
                            size="small"
                            disableClearable={true}
                            style={{minWidth: "20%", maxWidth: "fit-content", margin: "auto"}}
                            options={Object.values(mapCurrenciesByCode)}
                            getOptionLabel={option => option.name + " (" + option.symbol + ")"}
                            value={rate.currencyCode ? mapCurrenciesByCode[rate.currencyCode] : defaultCurrency}
                            defaultValue={rate.currencyCode ? mapCurrenciesByCode[rate.currencyCode] : defaultCurrency}
                            noOptionsText={t("no_options")}
                            onChange={handleCurrencyChange}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t("currency")}
                                    variant="outlined"
                                    margin="dense"
                                    value={rate.currencyCode ? mapCurrenciesByCode[rate.currencyCode] : defaultCurrency}
                                    defaultValue={rate.currencyCode ? mapCurrenciesByCode[rate.currencyCode] : defaultCurrency}
                                />
                            )}
                        />
                        <Typography style={{margin: "auto", width: "fit-content", marginTop: "2%"}} variant={"subtitle2"}>{t("new_attempt_storage_release")}</Typography>
                        <div style={{display: "flex", marginTop: "2%"}}>
                            <CurrencyTextField
                                style={{width: "20%", margin: "auto"}}
                                label={t("freight_percentage")}
                                variant={"outlined"}
                                margin={"dense"}
                                digitGroupSeparator={"."}
                                decimalCharacter={","}
                                decimalPlaces={0}
                                value={rate.freightPercentageNewAttempt}
                                currencySymbol={"%"}
                                onChange={onChangeFreightPercentageNewAttempt}
                            />
                            <CurrencyTextField
                                style={{width: "20%", margin: "auto"}}
                                label={t("fixed_fee")}
                                variant={"outlined"}
                                margin={"dense"}
                                digitGroupSeparator={"."}
                                decimalCharacter={","}
                                decimalPlaces={2}
                                value={rate.fixedFeeNewAttempt}
                                currencySymbol={"€"}
                                onChange={onChangeFixedFeeNewAttempt}
                            />
                        </div>
                        <Typography style={{margin: "auto", width: "fit-content", marginTop: "2%"}} variant={"subtitle2"}>{t("address_change_storage_release")}</Typography>
                        <div style={{display: "flex", marginTop: "2%"}}>
                            <CurrencyTextField
                                style={{width: "20%", margin: "auto"}}
                                label={t("freight_percentage")}
                                variant={"outlined"}
                                margin={"dense"}
                                digitGroupSeparator={"."}
                                decimalCharacter={","}
                                decimalPlaces={0}
                                value={rate.freightPercentageAddressChange}
                                currencySymbol={"%"}
                                onChange={onChangeFreightPercentageAddressChange}
                            />
                            <CurrencyTextField
                                style={{width: "20%", margin: "auto"}}
                                label={t("fixed_fee")}
                                variant={"outlined"}
                                margin={"dense"}
                                digitGroupSeparator={"."}
                                decimalCharacter={","}
                                decimalPlaces={2}
                                value={rate.fixedFeeAddressChange}
                                currencySymbol={"€"}
                                onChange={onChangeFixedFeeAddressChange}
                            />
                        </div>
                        <Typography style={{margin: "auto", width: "fit-content", marginTop: "2%"}} variant={"subtitle2"}>{t("return_to_sender_storage_release")}</Typography>
                        <div style={{display: "flex", marginTop: "2%"}}>
                            <CurrencyTextField
                                style={{width: "20%", margin: "auto"}}
                                label={t("freight_percentage")}
                                variant={"outlined"}
                                margin={"dense"}
                                digitGroupSeparator={"."}
                                decimalCharacter={","}
                                decimalPlaces={0}
                                value={rate.freightPercentageReturnToSender}
                                currencySymbol={"%"}
                                onChange={onChangeFreightPercentageReturnToSender}
                            />
                            <CurrencyTextField
                                style={{width: "20%", margin: "auto"}}
                                label={t("fixed_fee")}
                                variant={"outlined"}
                                margin={"dense"}
                                digitGroupSeparator={"."}
                                decimalCharacter={","}
                                decimalPlaces={2}
                                value={rate.fixedFeeReturnToSender}
                                currencySymbol={"€"}
                                onChange={onChangeFixedFeeReturnToSender}
                            />
                        </div>
                    </CardContent>

                    <Notification open={openNotify} severity={notifySeverity} duration={3500}
                                  onClose={closeNotification}>{notificationMessage}</Notification>
                </Card>

                <div style={{display: "flex"}}>
                    <Button
                        className={classes.add_service_button}
                        endIcon={<AddOutlined/>}
                        onClick={() => {
                            setShowModalNewServiceCode(true);
                        }}
                        variant={"contained"}>
                        {t("add_service")}
                    </Button>

                    <Button
                        className={classes.add_service_button}
                        endIcon={<CopyAllOutlined/>}
                        onClick={() => {
                            setShowModalDuplicateServiceCode(true);
                        }}
                        variant={"contained"}>
                        {t("duplicate_service")}
                    </Button>

                    <Modal
                        open={showModalDuplicateServiceCode}
                        onClose={()=>setShowModalDuplicateServiceCode(false)}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500,
                        }}
                    >
                        {/*aaa*/}
                        <Fade in={showModalDuplicateServiceCode}>
                            <Paper variant={"outlined"} elevation={2} style={{
                                width: "25%",
                                margin: "auto",
                                marginTop: "25%",
                                textAlign: "center"
                            }}>
                                <div>
                                    <DialogTitle style={{margin: "auto"}}>{t("select_source_service")}</DialogTitle>
                                    <Autocomplete
                                        style={{width: "50%", margin: "auto"}}
                                        id="serviceCode"
                                        size={"small"}
                                        options={getAvailableRateServiceBlockServices()}
                                        // options={services}
                                        getOptionLabel={option => option.code + " - " + option.name}
                                        noOptionsText={t("no_options")}
                                        onChange={handleSourceServiceChange}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label={t("source_service")}
                                                variant="outlined"
                                                margin="dense"
                                                value={sourceService}
                                            />
                                        )}
                                    />
                                    <DialogTitle style={{margin: "auto"}}>{t("select_target_service")}</DialogTitle>
                                    <Autocomplete
                                        style={{width: "50%", margin: "auto"}}
                                        id="serviceCode"
                                        size={"small"}
                                        // options={getAvailableRateServiceBlockServices()}
                                        options={services}
                                        getOptionLabel={option => option.code + " - " + option.name}
                                        noOptionsText={t("no_options")}
                                        onChange={handleTargetServiceChange}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label={t("target_service")}
                                                variant="outlined"
                                                margin="dense"
                                                value={targetService}
                                            />
                                        )}
                                    />
                                    <Button
                                        style={{width: "40%", backgroundColor: theme.palette.primary.main, color: "white", marginBlock: "5%"}}
                                        endIcon={<ContentCopyOutlined className={classes.confirm_icon}/>}
                                        onClick={duplicateRateServiceBlock}
                                        variant={"contained"}>
                                        {t("duplicate")}
                                    </Button>
                                </div>

                            </Paper>
                        </Fade>
                    </Modal>

                    <Modal
                        open={showModalNewServiceCode}
                        onClose={closeModalNewServiceCode}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500,
                        }}
                    >
                        {/*aaa*/}
                        <Fade in={showModalNewServiceCode}>
                            <Paper variant={"outlined"} elevation={2} style={{
                                width: "25%",
                                marginInline: "auto",
                                marginTop: "15%",
                                height: "10%",
                                display: "flex"
                                // border: `1px solid black`
                            }}>

                                <div style={{display: "flex", width: "100%", margin: "auto"}}>
                                    <Typography variant={"button"} style={{
                                        width: "50%",
                                        margin: "auto",
                                        marginLeft: "5%"
                                    }}>{t("select_a_service")}</Typography>
                                    <Autocomplete
                                        style={{width: "50%", margin: "auto", marginRight: "5%"}}
                                        id="serviceCode"
                                        size={"small"}
                                        // options={getAvailableRateServiceBlockServices()}
                                        options={services}
                                        getOptionLabel={option => option.code + " - " + option.name}
                                        noOptionsText={t("no_options")}
                                        onChange={handleServiceCodeChange}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                // label={t("service")}
                                                variant="outlined"
                                                margin="dense"
                                            />
                                        )}
                                    />
                                </div>

                            </Paper>
                        </Fade>
                    </Modal>


                    {/*<Dialog open={showModalNewServiceCode}*/}
                    {/*        onClose={closeModalNewServiceCode}*/}
                    {/*        style={{width: "50%", margin: "auto"}}*/}
                    {/*>*/}
                    {/*    */}
                    {/*</Dialog>*/}
                </div>

                {Object.keys(rate.serviceBlocks ? rate.serviceBlocks : {}).map((serviceCode, index) => {
                    return <div style={{marginBottom: "5%"}}>
                        <RateServiceBlockForm
                            removeBlock={removeBlock}
                            style={{marginTop: "5%"}}
                            key={serviceCode}
                            rateServiceBlock={rate.serviceBlocks[serviceCode]}
                            onStateChange={(newRateServiceBlock) => {
                                onRateServiceBlockChange(index, newRateServiceBlock);
                            }}
                        />
                    </div>


                })}

                <div style={{marginTop: "15px"}}>
                    <CardActions className={classes.button_container}>
                        <Button
                            className={classes.cancel_button}
                            endIcon={<CancelOutlined/>}
                            onClick={handleClose}
                            variant={"contained"}>
                            {t("cancel")}
                        </Button>
                        <Button
                            className={classes.confirm_button}
                            endIcon={<CheckCircleOutlined className={classes.confirm_icon}/>}
                            onClick={handleSubmit}
                            color="primary"
                            variant={"contained"}>
                            {t("confirm")}
                        </Button>
                    </CardActions>
                </div>
            </div>
        }
    </div>
}

export default RateForm;